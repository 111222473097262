/* eslint-disable */
import axios from 'axios';

let transport;

// const isProd = false;

const isMode = 'DEV2'; // "DEV" || "LOCAL" | "PROD" || "DEV2"

const apiUrlDomain = {
  PROD: 'https://backend-vurv-ea3516817a85.herokuapp.com',
  DEV: 'http://vurv.demowts.ru:3080',
  LOCAL: 'http://localhost:3080',
  DEV2: 'https://vurv.devapi.demowts.ru'
};

export const apiUrl = apiUrlDomain[isMode];
export const uploadURL = `${apiUrlDomain[isMode]}/`;

export const serviceKey = '1234';

export const configureTransport = async (token = null) => {
  const options = token
    ? {
        baseURL: apiUrl,
        headers: {
          Authorization: token,
          access_token: token
        }
      }
    : { baseURL: apiUrl };

  transport = axios.create(options);

  transport.interceptors.response.use(
    (response) => response,
    (error) => console.log(error)
  );

  return transport;
};

export const getTransport = () => transport || configureTransport();
