/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Alert,
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Breadcrumbs,
    Tab,
    Container,
    CircularProgress
} from '@material-ui/core';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    TabContext,
    TabList,
    TabPanel
} from '@material-ui/lab';
import { useGet, usePost } from '../request';
import { serviceKey } from '../enviroment';

const AdvertAdd = function() {

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    let [link, setLink] = useState('');
    let [selectedType, setSelectedType] = useState('');
    let [selectedNetworkRedirect, setSelectedNetworkRedirect] = useState('');
    const [tabName, setTabName] = useState('file');
    let [imgDescr, setImgDescr] = useState('');
    let [imgLink, setImgLink] = useState('');
    const [types, setTypes] = useState([]);
    const [description, setDescription] = useState('');

    const navigate = useNavigate();
    let get = useGet();
    let post = usePost();
    let imgUpload = React.useRef();

    // текст для алертов по умолчанию
    let [successAlertDefaultTxt] = useState('Successfully added!');
    let [errAlertDefaultTxt] = useState('Error: data is not sent!');

    // данные переменные привязываются к алерту - в нее выводим текст, который будем в нем показывать
    let [successAlertTxt, setSuccessAlertTxt] = useState(successAlertDefaultTxt);
    let [errAlertTxt, setErrAlertTxt] = useState(errAlertDefaultTxt);

    let alertsStyle = {
        marginTop: '10px',
        display: 'none'
    };

    let [successAddStyles, setSuccessAddStyles] = useState(alertsStyle);
    let [failedAddStyles, setFailedAddStyles] = useState(alertsStyle);

    let [selectImgStyles, setSelectImgStyles] = useState({
        display: 'inline-block',
        padding: '6px 16px'
    });

    let [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        get('admin/provider-ad-types')
            .then((response) => {
                setIsLoading(false);
                if (response.result !== 'success') {
                    return;
                }
                setTypes(response.types);
            })
            .catch((e) => {
                setIsLoading(false);
                console.log(e);
            });
    }, []);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const truncatedValue = inputValue.slice(0, 255);
        setDescription(truncatedValue);
      };

    function selectedTypeChanged(evt) {
        setSelectedType(evt.target.value);
    }

    function selectedNetworkRedirectChanged(evt) {
        setSelectedNetworkRedirect(evt.target.value);
    }

    function imageUploaded(evt) {
        setImgDescr(evt.target.files[0].name);
    }

    function validateLink() {
        let result = true;

        if (imgLink.trim() == '') {
            setErrAlert('Type an advert image link');
            result = false;
        } else if (selectedType === '') {
            setErrAlert('Type an advert type');
            result = false;
        }

        return result;
    }

    function validateNetworkRedirect() {
        let result = true;

        if (selectedType === selectedNetworkRedirect) {
            setErrAlert('Type and Network Redirect cannot be the same');
            result = false;
        }
        return result;
    }

    function validateFile() {
        let result = true;

        if (imgUpload.current.value == '') {
            setErrAlert('Select an advert image');
            result = false;
        } else if (selectedType === '') {
            setErrAlert('Type an advert type');
            result = false;
        }

        return result;
    }

    function resetForm() {
        setImgLink('');
        if (imgUpload.current) {
            imgUpload.current.value = '';
        }
        setLink('');
        setImgDescr('');
        setSelectedType('');
        setSelectedNetworkRedirect('');
    }

    // показывает успешный alert
    function setSuccessAlert(txt = successAlertDefaultTxt) {
        setSuccessAlertTxt(txt);
        setSuccessAddStyles({ ...successAddStyles, display: 'flex' });
        setTimeout(() => {
            setSuccessAddStyles({ ...successAddStyles, display: 'none' });
            setSuccessAlertTxt(successAlertDefaultTxt);
            setSubmitBtnDisabled(false);
        }, 2000);
    }

    // показывает ошибочный alert
    function setErrAlert(txt = errAlertDefaultTxt) {
        setErrAlertTxt(txt);
        setFailedAddStyles({ ...failedAddStyles, display: 'flex' });
        setTimeout(() => {
            setFailedAddStyles({ ...failedAddStyles, display: 'none' });
            setErrAlertTxt('Error: ad is not added!');
            setSubmitBtnDisabled(false);
        }, 2000);
    }

    const addAd = (requestData) => {
        console.log('addAd', requestData);
        setIsLoading(true);
        post(`admin/provider-ad`, requestData)
            .then(response => {
                setIsLoading(false);
                if (response.result == 'success') {
                    setSuccessAlert();
                    resetForm();
                } else {
                    setErrAlert();
                }
            })
            .catch(err => {
                setIsLoading(false);
                console.log('Data send error!');
                console.log(new Error(err));
                setErrAlert();
            });
    };

    function addNewAd() {
        if (tabName === 'link') {
            if (validateLink()) {
                if (validateNetworkRedirect()) {
                    let requestData = {
                    link: link.trim(),
                    image_uri: imgLink,
                    type_id: selectedType,
                    network_redirect: selectedNetworkRedirect,
                    group_id: id,
                    description: description
                };
                addAd(requestData);
                }
            }
        }
        if (tabName === 'file') {
            if (validateFile()) {
                if (validateNetworkRedirect()) {
                    let formData = new FormData();
                        let file = imgUpload.current.files[0];
                        formData.append('file', file);
                        formData.append('service_key', serviceKey);
                        setSubmitBtnDisabled(true);
                        setIsLoading(true);
                        post('admin/upload-file', formData)
                            .then(response => {
                                setIsLoading(false);
                                if (response.result == 'success') {
                                    let requestData = {
                                        link: link.trim(),
                                        image_uri: response.full_uri,
                                        type_id: selectedType,
                                        network_redirect: selectedNetworkRedirect,
                                        group_id: id,
                                        description: description
                                    };

                                    addAd(requestData);
                                } else {
                                    setErrAlert();
                                }
                            })
                            .catch(err => {
                                setIsLoading(false);
                                console.log('Advert add error!');
                                console.log(new Error(err));
                                setErrAlert();
                            });
                    }
            }
        }
    }

    if (isLoading) {
        return (
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 100
            }}
            >
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Create new advertisement | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/adverts">
                                Advertisements
                            </RouterLink>
                            <p>Create</p>
                        </Breadcrumbs>
                    </Box>
                    <form id="mainForm">
                        <Card>
                            <CardHeader
                                title="Create new ad"
                            />
                            <Divider/>
                            <CardContent>
                                <TabContext value={tabName}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={(elem, newValue) => setTabName(newValue)}
                                                 aria-label="lab API tabs example">
                                            <Tab label="File" value="file"/>
                                            <Tab label="Link" value="link"/>
                                        </TabList>
                                    </Box>
                                    <TabPanel sx={{ p: 0 }} value="file">
                                        <Button color="primary" variant="contained" sx={{ padding: 0, my: 2 }}>
                                            <label htmlFor="uploadImg" style={selectImgStyles}>
                                                Select image
                                                <input type="file"
                                                       name="uploadAdImg"
                                                       id="uploadImg"
                                                       accept=".jpg, .jpeg, .png, .svg, .gif"
                                                       style={{ display: 'none' }}
                                                       ref={imgUpload}
                                                       onChange={imageUploaded}
                                                />
                                            </label>
                                        </Button>
                                        <span style={{
                                            marginLeft: '7px',
                                            fontFamily: 'Roboto, Helvetica, sans-serif'
                                        }}>{imgDescr}</span>
                                    </TabPanel>
                                    <TabPanel sx={{ p: 0 }} value="link">
                                        <TextField
                                            fullWidth
                                            label="Image link"
                                            margin="normal"
                                            name="link"
                                            type="text"
                                            value={imgLink}
                                            onChange={evt => setImgLink(evt.target.value)}
                                            variant="outlined"
                                        />
                                    </TabPanel>
                                </TabContext>
                                <TextField
                                    fullWidth
                                    label="Link"
                                    margin="normal"
                                    name="link"
                                    type="text"
                                    value={link}
                                    onChange={evt => setLink(evt.target.value)}
                                    variant="outlined"
                                />
                                <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                    <InputLabel id="typeLabel">Type</InputLabel>
                                    <Select
                                        labelId="typeLabel"
                                        name="adType"
                                        label="Type"
                                        value={selectedType}
                                        onChange={selectedTypeChanged}
                                    >
                                        {types.map(({ id, type_name }) => (
                                            <MenuItem key={id} value={id}>{type_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                    <InputLabel id="selectedNetworkRedirectLabel">Network redirect</InputLabel>
                                    <Select
                                        labelId="selectedNetworkRedirectLabel"
                                        name="selectedNetworkRedirect"
                                        label="Network redirect"
                                        value={selectedNetworkRedirect}
                                        onChange={selectedNetworkRedirectChanged}
                                    >
                                        {types.filter(({ id }) => id != 6).map(({ id, type_name }) => (
                                            <MenuItem key={id} value={id}>{type_name}</MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        margin="normal"
                                        name="description"
                                        onChange={handleChange}
                                        type="text"
                                        value={description}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        inputProps={{ maxLength: 255 }}
                                    />
                                </FormControl>
                                <Alert severity="success" sx={successAddStyles}>{successAlertTxt}</Alert>
                                <Alert severity="error" sx={failedAddStyles}>{errAlertTxt}</Alert>
                            </CardContent>
                            <Divider/>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={addNewAd}
                                    disabled={submitBtnDisabled}
                                >
                                    Create
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Container>
            </Box>
        </>
    );
};

export default AdvertAdd;
