/* eslint-disable */
import React, { useState, useMemo, useEffect } from 'react';
import {
    Box,
    Container,
    Button,
    Breadcrumbs,
    Typography,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import AdvertsView from '../components/AdvertsView';
import { useGet, useHttpDelete, usePost } from '../request';
import { useConfirm } from '../components/confirm';
import { Helmet } from 'react-helmet';
import '../styles/Adverts.css';
import { useAlert } from '../components/alert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';

// страница с рекламными объявлениями
const Adverts = function() {

    const navigate = useNavigate();
    const confirm = useConfirm();
    // виды отображения объявлений (плиткой, списком и тд)
    let viewSelectOptions = [
        { value: 'Tile' },
        { value: 'List' }
    ];

    const alert = useAlert();
    const get = useGet();
    const post = usePost();
    const httpDelete = useHttpDelete();

    // выбранный в текущий момент вид отображения
    // по дефолту - плиткой
    // лист групп для выборки
    // выбранная группа
    const [selectedView, setSelectedView] = useState('Tile');
    const [advertsData, setAdvertsData] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [typeIdConverter, setTypeIdConverter] = useState({});
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    // обрабатывает выбор юзером другого стиля
    // отображения объявлений
    function viewTypeChanged(newType) {
        setSelectedView(newType);
    }

    // получаем данные всех типов
    function loadTypes() {

        const point = 'admin/provider-ad-types';

        get(point)
            .then((response) => {
                if (response.result !== "success") {
                    return;
                }
                const types = {};
                response.types.forEach((item) => {
                    types[item.id] = item.type_name;
                })
                console.log(types)
                setTypeIdConverter(types);
            })
            .catch((e) => console.log(e));
    }

    // получаем данные об объявлениях с сервера
    function getAdvertsData() {

        const point = `admin/provider-ad?group=${selectedGroup}`;

        get(point)
            .then(response => {
                if (response.result == 'success') {
                    setAdvertsData(response.returnList);
                }
            })
            .catch(err => {
                console.log('Error!');
                console.log(new Error(err));
            });
    }

    function deleteAdvert(adId) {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the advert?',
            isDeleting: true,
            onConfirm: () => {
                httpDelete(`admin/provider-ad/${adId}`)
                    .then(response => {
                        if (response.result === 'error') {
                            alert({
                                status: 'error',
                                message: response.message
                            });
                            return;
                        }
                        getAdvertsData();
                    })
                    .catch(err => {
                        console.log(new Error(err));
                    });
            }
        });
    }

    useEffect(() => {
        if (!isFirstLoad) {
            getAdvertsData();
        }
    }, [selectedGroup]);

    useEffect(() => {
        const requestData = {
            filters: {
                limit: 50,
                page: 0
            }
        };
        post('admin/groups', requestData)
            .then((response) => {
                if (response.result === 'success') {
                    setGroupList(response.groups);
                    loadTypes();
                    getAdvertsData()
                    setIsFirstLoad(false);
                }
            })
            .catch((e) => console.log(e));
    }, []);

    if (!advertsData) {
        return (
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 100
            }}
            >
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Advertisements | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Advertisements
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                <RouterLink to={`/app/adverts/add/${selectedGroup}`}>
                                    <Button color="primary" variant="contained">
                                        Add
                                    </Button>
                                </RouterLink>

                                {/*<Box sx={{ ml: 1 }}>*/}
                                {/*    <SelectList*/}
                                {/*      options={viewSelectOptions}*/}
                                {/*      defaultVal={selectedView}*/}
                                {/*      changeHandle={viewTypeChanged}*/}
                                {/*    />*/}
                                {/*</Box>*/}
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Advertisements</p>
                        </Breadcrumbs>
                    </Box>

                    <Card>
                        <Box sx={{ pt: 1, px: 2 }} fullWidth>
                            <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                <InputLabel id="searchType">
                                    Select the group type
                                </InputLabel>
                                <Select
                                    labelId="searchType"
                                    name="searchValue"
                                    value={selectedGroup}
                                    label="Select the group type"
                                    onChange={(evt) => setSelectedGroup(evt.target.value)}
                                >
                                    <MenuItem value={0}>
                                        Without Group
                                    </MenuItem>
                                    {
                                        groupList?.map((item) =>
                                            <MenuItem key={item?.id} value={item?.id}>
                                                {item?.name}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>

                        <AdvertsView adverts={advertsData} viewType={'List'} deleteAd={deleteAdvert} types={typeIdConverter}/>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Adverts;
