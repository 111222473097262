import { Helmet } from 'react-helmet';
import {
    Box,
    Container
} from '@material-ui/core';
import GroupFormCreate from '../components/group/GroupFormCreate';

const GroupAdd = () => (
    <>
        <Helmet>
            <title>Create new group | Material Kit</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
            <Container maxWidth={false}>
                <GroupFormCreate />
            </Container>
        </Box>
    </>
);

export default GroupAdd;
