/* eslint-disable */
import React, { useState , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Typography,
    Breadcrumbs,
    Button,
    CircularProgress,
    Tab,
    Tabs
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useGet, usePost } from "../request";
import SubscriptionsTable from '../components/SubscriptionsTable';
import {useConfirm} from "../components/confirm";
import {useAlert} from "../components/alert";
import SubscriptionsBaseProducts from '../components/SubscriptionsBaseProducts';
import SubscriptionsSubProducts from '../components/SubscriptionSubProducts';

const Subscriptions = function () {
    const post = usePost();
    const get = useGet();
    const [isShowLoader, setIsShowLoader] = useState(false);
    const navigate = useNavigate();
    const confirm = useConfirm();
    const alert = useAlert();

    const [subscriptions, setSubscriptions] = useState([]);
    const [tab, setTab] = useState(0);

    const handleTab = (_, value) => setTab(value);

    const rsyncStripe = () => {
        confirm({
            title: 'Synchronization',
            content: 'Are you sure you want to sync the subscriptions?',
            isDeleting: true,
            onConfirm: () => {
                post(`api/strapi/sync-prices`)
                    .then((response) => {
                        if (response.result === 'error') {
                            alert({
                                status: 'error',
                                message: 'Unknown error'
                            });
                            return;
                        }
                        alert({
                            status: 'success',
                            message: 'Successfully'
                        });
                        loadSubscriptions();
                    })
                    .catch((e) => console.log(e));
            }
        });
    };

    const syncProducts = () => {
      confirm({
          title: 'SynchronizationSub',
          content: 'Are you sure you want to sync the subscriptions?',
          isDeleting: true,
          onConfirm: () => {
              post(`api/strapi/sync-subs`)
                  .then((response) => {
                      if (response.result === 'error') {
                          alert({
                              status: 'error',
                              message: 'Unknown error'
                          });
                          return;
                      }
                      alert({
                          status: 'success',
                          message: 'Successfully'
                      });
                      loadSubscriptions();
                  })
                  .catch((e) => console.log(e));
          }
      });
  };

    const loadSubscriptions = () => {
        setIsShowLoader(true);
        get('admin/subscriptions')
        .then((response) => {
            if (response.result === 'success') {
                setSubscriptions(response.subscriptions || []);
            }
        })
        .catch((e) => console.log(e))
        .finally(() => setIsShowLoader(false));
    };

    useEffect(() => {
        loadSubscriptions();
    }, []);

    return (
    <>
      <Helmet>
        <title>Subscriptions | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                  Back
                </Button>
              </Box>
              <Typography color="textPrimary" variant="h4" component="h1">
                Vurv Products (Subscriptions)
              </Typography>
              <Box sx={{ display: 'flex', flex: 4.1, justifyContent: 'flex-end', columnGap: 1 }}>
                <Button color='primary' variant="contained" onClick={syncProducts}>
                  Sync Products
                </Button>
                <Button color="info" variant="contained" onClick={rsyncStripe}>
                Price Sync
                </Button>
                <RouterLink to={`/app/subscriptions/add`}>
                  <Button color="primary" variant="contained">
                    Create Subs
                  </Button>
                </RouterLink>
              </Box>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <p>Vurv Products</p>
            </Breadcrumbs>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleTab} aria-label="basic tabs example">
              <Tab label="All" id="simple-tab-0" {...{ 'aria-controls': 'simple-tabpanel-0' }} />
              <Tab label="Base Products" id="simple-tab-1" {...{ 'aria-controls': 'simple-tabpanel-1' }} />
              <Tab label="Sub Products" id="simple-tab-2" {...{ 'aria-controls': 'simple-tabpanel-2' }} />
            </Tabs>
          </Box>
          <Box hidden={tab !== 0} id="simple-tabpanel-0" aria-labelledby="simple-tab-0">
            {tab === 0 && (
              <Box sx={{ paddingBottom: 2 }}>
                <SubscriptionsTable subscriptions={subscriptions}/>
              </Box>
              )}
          </Box>
          <Box hidden={tab !== 1} id="simple-tabpanel-1" aria-labelledby="simple-tab-1">
            {tab === 1 && (
              <Box sx={{ paddingBottom: 2 }}>
                <SubscriptionsBaseProducts subscriptions={subscriptions}/>
              </Box>
              )}
          </Box>
          <Box hidden={tab !== 2} id="simple-tabpanel-2" aria-labelledby="simple-tab-2">
            {tab === 2 && (
              <Box sx={{ paddingBottom: 2 }}>
                <SubscriptionsSubProducts subscriptions={subscriptions}/>
              </Box>
              )}
          </Box>
        </Container>
      </Box>
    </>
        );
    };

export default Subscriptions;
