import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Breadcrumbs,
    Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CustomerListResults from '../components/customer/CustomerListResults';
import { useHttpDelete } from '../request';
import { useConfirm } from '../components/confirm';
import { useAlert } from '../components/alert';

const ExpiredCustomers = () => {
    const [reloadCounter, setReloadCounter] = useState(0);
    const navigate = useNavigate();
    const httpDelete = useHttpDelete();
    const confirm = useConfirm();
    const alert = useAlert();

    const handleDeleteAll = () => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete users?',
            isDeleting: true,
            onConfirm: () => {
                httpDelete('admin/users/move-to-removed-list')
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: response.message || 'Unknown error'
                        });
                        return;
                    }
                    setReloadCounter((prev) => prev + 1);
                })
                .catch((e) => console.log(e));
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Customers with expired payments | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Customers with expired payments
                            </Typography>
                            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                <Button onClick={handleDeleteAll} color="error" variant="contained">
                                    Delete All
                                </Button>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Customers with expired payments</p>
                        </Breadcrumbs>
                    </Box>
                    <CustomerListResults filters={{ soft_delete: 0, itsOldPayment: true }} reloadCounter={reloadCounter} />
                </Container>
            </Box>
        </>
    );
};

export default ExpiredCustomers;
