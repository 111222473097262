import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Breadcrumbs,
    Typography,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DocumentsListResults from '../components/documents/DocumentsListResults';
import { useAlert } from '../components/alert';
import {
    useGet,
    useHttpDelete,
    usePut
} from '../request';
import { useConfirm } from '../components/confirm';

const DocumentsList = () => {
    const [documents, setDouments] = useState(null);
    const get = useGet();
    const put = usePut();
    const httpDelete = useHttpDelete();
    const navigate = useNavigate();
    const alert = useAlert();
    const confirm = useConfirm();

    const loadDocuments = () => {
      get('admin/get-docs')
      .then((response) => {
        if (response.result === 'success') {
          setDouments(response.docsList);
        }
      })
      .catch((e) => console.log(e));
    };

    useEffect(() => {
        loadDocuments();
    }, []);

    const deleteDocument = (id) => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the document?',
            isDeleting: true,
            onConfirm: () => {
                httpDelete(`admin/get-docs/${id}`)
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: response.message
                        });
                        return;
                    }
                    loadDocuments()
                })
                .catch((e) => console.log(e));
            }
        });
    };

    if (!documents) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Documents | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                              Documents List
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                <RouterLink to="/app/documents-add">
                                    <Button color="primary" variant="contained">
                                        Add Document
                                    </Button>
                                </RouterLink>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Documents List</p>
                        </Breadcrumbs>
                    </Box>
                      <DocumentsListResults documents={documents} deleteDocument={deleteDocument} />
                </Container>
            </Box>
        </>
    );
};

export default DocumentsList;
