import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import getPrice from '../utils/getPrice';

const SubscriptionsTable = function ({ subscriptions }) {
    const navigate = useNavigate();

    if (subscriptions.length === 0) {
        return (
            <Typography color="textPrimary" variant="body1" component="p" sx={{ p: 2, textAlign: 'center', backgroundColor: '#fff' }}>
                Subscriptions not found
            </Typography>
        );
    }

    return (
        <Box>
            <Table sx={{ backgroundColor: '#fff', boxShadow: '0 0 0 1px rgb(63, 63, 68, 0.1)', borderRadius: '4px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Id
                        </TableCell>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Amount ($)
                        </TableCell>
                        <TableCell>
                            Duration (d)
                        </TableCell>
                        <TableCell>
                            VurvProduct Type
                        </TableCell>
                        <TableCell>
                            Stripe plan id
                        </TableCell>
                        <TableCell>
                            Group id
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        subscriptions.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    {item.id}
                                </TableCell>
                                <TableCell>
                                    {item.name}
                                </TableCell>
                                <TableCell>
                                    {getPrice(item.amount || 0)}
                                </TableCell>
                                <TableCell>
                                    {item.duration}
                                </TableCell>
                                <TableCell>
                                    {item.its_sub_products === 0 ? 'Base subscription' : 'Sub Product'}
                                </TableCell>
                                <TableCell>
                                    {item.stripe_plan_id}
                                </TableCell>
                                <TableCell>
                                    {item?.group_id === 0 || !item?.group_id ? '---' : item?.group_id}
                                </TableCell>
                                <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                      onClick={() => navigate(`/app/subscription-info/${item.id}`)}
                                      color="primary"
                                      variant="contained"
                                    >
                                        Info
                                    </Button>
                                    <Button
                                      onClick={() => navigate(`/app/subscriptions/edit/${item.id}`)}
                                      color="primary"
                                      variant="contained"
                                      sx={{ ml: 2 }}
                                    >
                                        Edit
                                    </Button>
                                </Box>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    );
};

SubscriptionsTable.propTypes = {
    subscriptions: PropTypes.array.isRequired
};

export default SubscriptionsTable;
