import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box
} from '@material-ui/core';
import PropTypes from 'prop-types';

const TotalProfit = ({
  title,
  count,
  active,
  deactive,
  ...props
}) => (
  <Card {...props}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            {title}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ mr: 1 }} color="textPrimary" variant="h6">
              Count:
            </Typography>
            <Typography color="textPrimary" variant="h5">
              {count}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ mr: 1 }} color="textPrimary" variant="h6">
              Active:
            </Typography>
            <Typography color="textPrimary" variant="h5">
              {active}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ mr: 1 }} color="textPrimary" variant="h6">
              Deactive:
            </Typography>
            <Typography color="textPrimary" variant="h5">
              {deactive}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

TotalProfit.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
    deactive: PropTypes.string.isRequired,
};

export default TotalProfit;
