/* eslint-disable */

import { Line } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { blue } from '@material-ui/core/colors';
import { useSales } from './useSales';
import { useEffect, useRef, useState } from 'react';

const Sales = ({ ...props }) => {
  const [isVisibility, setIsVisibility] = useState(false);
  const { labels, datasets, loadData } = useSales();
  const theme = useTheme();
  const ref = useRef();

  const d = {
    labels,
    datasets: datasets.map((data) => {
      let c = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      return {
        ...data,
        borderColor: c,
        backgroundColor: c
      };
    })
  };

  const options = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        position: 'top'
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.parsed.y}ms`
        }
      }
    }
  };

  const callbackFun = (entries) => {
    if (isVisibility) return;
    const [entry] = entries;

    if (entry.isIntersecting) {
      loadData();
      setIsVisibility(entry.isIntersecting);
    }
  };

  useEffect(() => {
    if (!ref.current ) return;

    const observer = new IntersectionObserver(callbackFun, {
      threshold: 0.5,
      root: null,
      rootMargin: '0px'
    });
    observer.observe(ref.current);
   
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    }
  }, [ref, isVisibility]);

  return (
    <Card {...props} ref={ref}>
      <CardHeader title="DB statistic" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            position: 'relative'
          }}
        >
          <Line options={options} data={d} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Sales;
