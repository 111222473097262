/* eslint-disable */
import React, { useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import UserData from './components/account/UserData';

/*
class App extends React.Component {
  constructor(props) {
      super(props);

      const content = useRoutes(routes);

      return (
          <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                  <GlobalStyles />
                  {content}
              </ThemeProvider>
          </StyledEngineProvider>
      );
  }
}
*/

const App = () => {
  const content = useRoutes(routes);
  const navigate = useNavigate();
  const usrData = UserData();

  try {
    if (
      content &&
      content.props &&
      content.props.value &&
      content.props.value.route &&
      content.props.value.route.path === 'app' &&
      !usrData.access_token
    ) {
      navigate('/login', { replace: true });
    }
  } catch (e) {
    console.log(e);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {content}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
