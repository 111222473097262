import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Breadcrumbs,
    Button,
    CircularProgress
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    usePost,
    useGet
} from '../../request';
import ServiceAlert from './ServiceAlert';

const ServiceFormEdit = (props) => {
    const { id } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');
    const [alert, setAlert] = useState({
      status: '',
      message: ''
    });
    const navigate = useNavigate();
    const post = usePost();
    const get = useGet();

    const handleName = (event) => {
        setName(event.target.value);
    };

    const submit = () => {
      setIsLoading(true);
      const requestData = {
        name,
      };

      // console.log(requestData);

      post(`admin/services/${id}`, requestData)
      .then((response) => {
        setIsLoading(false);
        if (response.result === 'success') {
          setAlert({
            status: response.result,
            message: 'Successfully'
          });
          return
        }
        setAlert({
          status: response.result,
          message: response.message
        });
      })
      .catch((e) => {
          setIsLoading(false);
          console.log(e)
      });
    };

    useEffect(() => {
        setIsLoading(true);
        get(`admin/services/${id}`)
        .then((response) => {
          setIsLoading(false);
          if (response.result === 'success') {
            setName(response.data.name);
          }
        })
        .catch((e) => {
            setIsLoading(false);
            console.log(e)
        });
    }, []);

    if (isLoading) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
      <>
        <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                <Box style={{ marginTop: 3, marginRight: 10 }}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Box>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                <RouterLink underline="hover" color="inherit" to="/app/services">
                    Networks
                </RouterLink>
                <p>{id}</p>
                <p>Edit</p>
            </Breadcrumbs>
        </Box>
        <form>
          <Card>
            <CardHeader
              title="Edit services"
            />
            <Divider />
            <CardContent>
                <TextField
                  fullWidth
                  label="Name"
                  margin="normal"
                  name="name"
                  onChange={handleName}
                  type="text"
                  value={name}
                  variant="outlined"
                />
                <ServiceAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
            </CardContent>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                >
                    Save
                </Button>
            </Box>
          </Card>
        </form>
      </>
    );
};

ServiceFormEdit.propTypes = {
  id: PropTypes.string.isRequired
};

export default ServiceFormEdit;
