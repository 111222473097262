/* eslint-disable */
import React, { useState , useEffect} from 'react';
import ReactJson from 'react-json-view';
import { Helmet } from 'react-helmet';
import {
    Card,
    CardContent,
    Box,
    Container,
    Typography,
    Breadcrumbs,
    Button,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import LogTable from '../components/LogTable';
import {useGet, usePost} from "../request";

const LogData = function () {
    let [searchParams] = useSearchParams();
    const [log, setLog] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            setLog(JSON.parse(searchParams.get("log")));
        }
        catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Log | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Log {log?.id}
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/logpage">
                                Log
                            </RouterLink>
                            <p>Log {log?.id}</p>
                        </Breadcrumbs>
                    </Box>
                    <Card>
                        <CardContent>
                            <ReactJson src={JSON.parse(log?.data || null)} theme="monokai" />
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default LogData;
