import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Tooltip
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import striptags from 'striptags';

const formattedDateTime = (createdAt) => {
    const dateObj = new Date(createdAt);
    return dateObj.toLocaleString('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
  });
}
const typeDocument = (type) => {
    if (type === 1) {
        return 'Membership_Agreement'
    }
    if (type === 2) {
        return 'Privacy_Policy'
    }
    if (type === 3) {
        return 'Terms_and_conditions'
    }
    return null
}

const DocumentsListResults = ({ documents, deleteDocument, ...rest }) => (
    <Card {...rest}>
        <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 80 }}>
                                ID
                            </TableCell>
                            <TableCell>
                              User ID
                            </TableCell>
                            <TableCell>
                              Doc Type
                            </TableCell>
                            <TableCell>
                              Context
                            </TableCell>
                            <TableCell>
                              Date Create
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((document) => (
                            <TableRow key={document.id}>
                                <TableCell sx={{ width: 80 }}>
                                    {document.id}
                                </TableCell>
                                <TableCell>
                                    {document.user_id}
                                </TableCell>
                                <TableCell>
                                    {typeDocument(document.docs_key)}
                                </TableCell>
                                <TableCell>
                                    <span
                                      style={{
                                        display: 'inline-block',
                                        maxWidth: '150px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {striptags(document.context) || '---'}
                                    </span>
                                </TableCell>
                                <TableCell>
                                    {formattedDateTime(document.date_create)}
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Box sx={{ ml: 2 }}>
                                            <RouterLink to={`/app/documents-edit/${document.id}`}>
                                                <Button color="primary" variant="contained">
                                                    Edit
                                                </Button>
                                            </RouterLink>
                                        </Box>
                                        <Box sx={{ ml: 2 }}>
                                            <Button color="primary" variant="contained" onClick={() => deleteDocument(document.id)}>
                                                Delete
                                            </Button>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </PerfectScrollbar>
    </Card>
);

DocumentsListResults.propTypes = {
    documents: PropTypes.array.isRequired,
    deleteDocument: PropTypes.func.isRequired,
};

export default DocumentsListResults;
