/* eslint-disable */
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import '../styles/AdvertTile.css';

// компонент - блок с объявлением (плитка)
const AdvertTile = ({advertData, typeIdConverter, deleteAd}) => {
    return (
        <div className="advert">

            <div className="advert__body">
                <div className="advert__head">

                    <div className="advert__type">
                        <h3>
                            {typeIdConverter[advertData.type_id]}
                        </h3>
                    </div>

                    <div className="advert__controls">
                        <RouterLink to={`/app/adverts/edit/${advertData.id}`}>
                            <Button color="primary"
                                    variant="contained"
                                    className="advert__edit-btn"
                            >Edit</Button>
                        </RouterLink>
                        <Button color="primary"
                                variant="contained"
                                className="advert__del-btn"
                                onClick={() => deleteAd(advertData.id)}
                        >Delete</Button>
                    </div>

                </div>

                <div className="advert__img">
                    <img src={advertData.image_uri} alt=""/>
                </div>

                <div className="advert__bottom">

                    <div className="advert__link">
                        <a href={advertData.link} target="_blank">{advertData.link}</a>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default AdvertTile;
