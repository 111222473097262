import { Helmet } from 'react-helmet';
import {
    Box,
    Breadcrumbs,
    Typography,
    Container,
    Button
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import VurvIdValidationForm from '../components/vurvIdValidation/VurvIdValidationForm';
import VurvIdValidationFormManualActivation from '../components/vurvIdValidation/VurvIdValidationFormManualActivation';

const VurvIdValidation = () => {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Vurv id validation | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Vurv id validation
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Vurv id validation</p>
                        </Breadcrumbs>
                    </Box>
                    <VurvIdValidationForm />
                    <Box sx={{ mt: 3 }}>
                        <VurvIdValidationFormManualActivation />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default VurvIdValidation;
