/* eslint-disable */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useGet } from 'src/request';
const useSales = () => {
  const [dbStatistic, setDbStatistic] = useState([]);
  const [dbStatisticLabels, setDbStatisticLabels] = useState([]);
  const [dbStatisticDatasets, setDbStatisticDatasets] = useState([]);
  const get = useGet();

  const loadDbStatistic = () => {
    get('admin/get-db-statistic')
      .then((response) => {
        if (response.result === 'success') {
          setDbStatistic(response.data);
        } else {
          throw response.message
            ? response.message
            : 'Failed fetch dashboard db statistic';
        }
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    const labels = [
      moment().subtract(6, 'days').format('DD.MM.YYYY'),
      moment().subtract(5, 'days').format('DD.MM.YYYY'),
      moment().subtract(4, 'days').format('DD.MM.YYYY'),
      moment().subtract(3, 'days').format('DD.MM.YYYY'),
      moment().subtract(2, 'days').format('DD.MM.YYYY'),
      moment().subtract(1, 'days').format('DD.MM.YYYY'),
      moment().format('DD.MM.YYYY')
    ];
    const keys = [...new Set(dbStatistic.map((item) => item.key))];
    const datasets = keys.map((key) => ({
      label: key,
      data: labels.map((date) =>
        dbStatistic
          .filter((item) => item.key === key)
          .reduce(
            (l, v) =>
              moment(v.date).format('DD.MM.YYYY') === date
                ? parseInt(v.value, 10)
                : l,
            0
          )
      )
    }));
    setDbStatisticLabels(labels);
    setDbStatisticDatasets(datasets);
  }, [dbStatistic]);

  // useEffect(() => {
  //   loadDbStatistic();
  // }, []);

  return {
    labels: dbStatisticLabels,
    datasets: dbStatisticDatasets,
    loadData: loadDbStatistic,
  };
};

export { useSales };
