import {
    useState,
    useRef,
    useEffect,
    useCallback,
    useMemo
} from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Checkbox,
    Button,
    Typography,
    Paper,
    Avatar,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Breadcrumbs
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import {
    useGet,
    usePost,
    usePut,
    useHttpDelete
} from '../request';
import getInitials from '../utils/getInitials';
import { uploadURL } from '../enviroment';
import GroupModalAddMember from '../components/group/GroupModalAddMember';
import { useConfirm } from '../components/confirm';
import { useAlert } from '../components/alert';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        paddingBottom: 16
    },
    wrapStatus: {
        flexDirection: 'row',
        display: 'flex',
        paddingBottom: 16,
        alignItems: 'center'
    },
    wrapEnd: {
        flexDirection: 'row',
        display: 'flex',
    },
    label: {
        width: 200,
        fontSize: 16,
        fontFamily: 'Roboto',
    },
    text: {
        fontSize: 16,
        fontFamily: 'Roboto'
    },
});

const SubscriptionInfo = () => {
    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [file, setFile] = useState(null);
    const [group, setGroup] = useState(null);
    const [users, setUsers] = useState([]);
    const [u, setU] = useState([]);
    const [members, setMembers] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [services, setServices] = useState([]);
    const [currentServices, setCurrentServices] = useState([]);
    const styles = useStyles();
    const get = useGet();
    const post = usePost();
    const put = usePut();
    const httpDelete = useHttpDelete();
    const alert = useAlert();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const [listMembersReload, setListMembersReload] = useState(false);
    const [info, setInfo] = useState(null);

    const loadInfo = () => {
        get(`admin/subscriptions/${id}`)
        .then((response) => {
          if (response.result === 'success') {
            console.log(response.data)
            setInfo(response.data)
            setGroup(true);
            setMembers(true);
            setServices(response.services_list);
            setListMembersReload(true)
          }
        })
        .catch((e) => console.log(e));

        get(`admin/subscriptions/get-customers-list/${id}`)
        .then((resp) => {
            setUsers(resp.usersList);
             })
        .catch((err) => {
            console.log(err.response);
            setUsers([]);
        })
    };

    const loadServices = () => {
        get('admin/services')
            .then((response) => {
                if (response.result === 'success') {
                    setAllServices(response.services);
                    loadInfo();
                }
            })
            .catch((e) => console.log(e));
    };

    const saveServices = () => {
        const requestData = {
            services_id_list: currentServices.reduce((list, item) => ({ ...list, [item.id]: item.checked }), {})
        };
        post(`admin/services/networks/${id}`, requestData) // 'admin/group/services'
        .then(() => {
            setIsEdit(false);
            loadInfo();
        })
        .catch((e) => console.log(e));
    };

    const handleChangeService = (service) => {
        setCurrentServices(currentServices.map((item) => {
            if (item.id === service.id) {
                return { ...item, checked: !item.checked }
            }
            return item;
        }));
    };

    useMemo(() => {
        loadServices();
    }, []);

    useEffect(() => {
        setCurrentServices(allServices.map((service) => ({
            ...service,
            checked: !!services.find((elem) => elem.services_id === service.id),
        })))
    }, [allServices, services]);

    if (!info) {
        return (
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 100
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Subscription | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                {info.name}
                            </Typography>
                            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                <RouterLink to={`/app/group-import/${id}`}>
                                    <Button color="primary" variant="contained" style={{ display: 'none' }}>
                                        Import
                                    </Button>
                                </RouterLink>
                                <Box sx={{ ml: 1 }}>
                                    <RouterLink to={`/app/group-export/${id}`}>
                                        <Button color="primary" variant="contained" style={{ display: 'none' }}>
                                            Export
                                        </Button>
                                    </RouterLink>
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                    <RouterLink to={`/app/group-expiration-date/${id}`}>
                                        <Button color="primary" variant="contained" style={{ display: 'none' }}>
                                            Set expiration date
                                        </Button>
                                    </RouterLink>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                    <RouterLink to={`/app/group/edit/${id}`}>
                                        <Button color="primary" variant="contained" style={{ display: 'none' }}>
                                            Edit
                                        </Button>
                                    </RouterLink>
                                </Box>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/subscriptions">
                                List of subscriptions
                            </RouterLink>
                                <p>{info.name}</p>
                        </Breadcrumbs>
                    </Box>
                    {/* Info */}
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title="Info" titleTypographyProps={{ variant: 'h5' }} component="h2" />
                        <Divider />
                        <CardContent>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Name:
                                </div>
                                <div className={styles.text}>
                                    {info.name || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Amount $:
                                </div>
                                <div className={styles.text}>
                                    {info.amount || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Duration (d):
                                </div>
                                <div className={styles.text}>
                                    {info.duration || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Vurv Product Type:
                                </div>
                                <div className={styles.text}>
                                    {info.its_sub_products === 0 ? 'Base subscription' : 'SubProduct'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    ID:
                                </div>
                                <div className={styles.text}>
                                    {info.id || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Stripe plan ID:
                                </div>
                                <div className={styles.text}>
                                    {info.stripe_plan_id || '---'}
                                </div>
                            </div>
                            <div className={styles.wrapStatus}>
                                <div className={styles.label}>
                                    Group ID:
                                </div>
                                <div className={styles.text}>
                                    {info.group_id || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Date create:
                                </div>
                                <div className={styles.text}>
                                    {info.date_create || 0}
                                </div>
                            </div>
                            <div className={styles.wrapEnd}>
                                <Typography className={styles.label} style={{ marginRight: '100px' }}>
                                    Description:
                                </Typography>
                                <div className={styles.text}>
                                    <Typography variant="body1" component="div" className={styles.descriptionText}>
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            {info.description || '---'}
                                        </div>
                                    </Typography>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Divider />
                    {info.its_sub_products !== 0 && (
                    <Card sx={{ mb: 2 }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          px: 2,
                          py: 1
                        }}
                        >
                            <Typography color="textPrimary" variant="h5" component="h2">
                                Networks
                            </Typography>
                            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                <Box sx={{ ml: 2 }}>
                                    { isEdit ? (
                                        <Button color="success" variant="contained" onClick={saveServices}>
                                            Save
                                        </Button>
                                    ) : (
                                        <Button color="primary" variant="contained" onClick={() => setIsEdit(true)}>
                                            Edit
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <CardContent>
                            { currentServices.length > 0 ? currentServices.map((service) => (
                                <Box key={uuidv4()} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Checkbox disabled={!isEdit} checked={service.checked} onChange={() => handleChangeService(service)} />
                                    <Typography color="textPrimary" variant="body1">
                                        {service.name}
                                    </Typography>
                                </Box>
                            )) : (
                                <Typography color="textPrimary" variant="body1" component="p" sx={{ alignItems: 'center', display: 'flex' }}>
                                    No found
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                    )}
                    <Card sx={{ mb: 2 }}>
                    <CardHeader title="Subscribers" titleTypographyProps={{ variant: 'h5' }} component="h2" />
                    <Divider />
                    {users && users.length > 0 ? (
                        <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell sx={{ width: 80 }}>Id</TableCell>
                            <TableCell>Avatar</TableCell>
                            <TableCell>First name</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>VurvID</TableCell>
                            <TableCell>Phone</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                            <TableRow hover key={user.id}>
                                <TableCell sx={{ width: 80 }}>
                                {user.user_id || '---'}
                                </TableCell>
                                <TableCell>
                                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Avatar src={user.user_avatar_url || ''} />
                                </Box>
                                </TableCell>
                                <TableCell>{user.user_first_name || '---'}</TableCell>
                                <TableCell>{user.user_last_name || '---'}</TableCell>
                                <TableCell>{user.user_email || '---'}</TableCell>
                                <TableCell>{user.user_vurv_id || '---'}</TableCell>
                                <TableCell>{user.user_phone || '---'}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    ) : (
                        <TableRow hover key={uuidv4()}>
                            <TableCell />
                            <TableCell>
                                No found
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    )}
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default SubscriptionInfo;
