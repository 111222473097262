import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Typography,
    Button,
    Breadcrumbs,
    CircularProgress,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    usePost,
    useGet
} from '../request';
import GroupAlert from '../components/group/GroupAlert';

const GroupImport = () => {
    const { id } = useParams();
    const [group, setGroup] = useState(null);
    const [file, setFile] = useState(null);
    const get = useGet();
    const post = usePost();
    const [isLoadingImport, setIsLoadingImport] = useState(false);
    const [filterMethod, setFilterMethod] = useState('default');

    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });
    const navigate = useNavigate();
    const inputImport = useRef();

    const handleFilterMethodChange = (event) => {
        setFilterMethod(event.target.value);
      };

    const handleFile = (event) => {
        setFile(event.target.files[0]);
    }

    useEffect(() => {
        get(`admin/groups/${id}`)
        .then((response) => {
          if (response.result === 'success') {
            setGroup(response.group);
          }
        })
        .catch((e) => console.log(e));
    }, []);

    const handleImport = () => {
        setIsLoadingImport(true);
        const formData = new FormData();
        formData.append('file', file);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        let currentEndPoint = '';

        if (filterMethod === 'default') {
            currentEndPoint = `admin/bulk-upload?group_id=${id}`
        }
        if (filterMethod === 'recuroBulk') {
            currentEndPoint = `admin/bulk-upload/recuro?group_id=${id}`
        }

        post(currentEndPoint, formData, { headers })
        .then((response) => {
            setIsLoadingImport(false);
            setFile(null);
            inputImport.current.value = '';
            if (response.result !== 'success') {
                setAlert({
                  status: 'error',
                  message: response.message
                });
                return;
            }
            let status = response.not_added ? 'info' : 'success';
            status = response.added ? status : 'error';
            setAlert({
              status,
              message: (response.added ? `Success added ${response.added} users! ` : '') + (response.not_added ? `Not added ${response.not_added} users` : '')
            });
        })
        .catch((e) => {
            console.log(e);
            setIsLoadingImport(false);
        });
    }

    if (!group || isLoadingImport) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Group import | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                {group.name}
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/groups">
                                Groups
                            </RouterLink>
                            <RouterLink underline="hover" color="inherit" to={`/app/group/${group.id}`}>
                                {group.name}
                            </RouterLink>
                            <p>Import</p>
                        </Breadcrumbs>
                    </Box>
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title="Import" titleTypographyProps={{ variant: 'h5' }} component="h2" />
                        <Divider />
                        <Paper sx={{ p: 4 }}>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                            >
                                <Grid item xs={12} sm={6} md={4}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="filter-method-label">Filter method</InputLabel>
                                    <Select
                                      labelId="filter-method-label"
                                      id="filter-method"
                                      value={filterMethod}
                                      onChange={handleFilterMethodChange}
                                      label="Import Type"
                                    >
                                    <MenuItem value="default">Default</MenuItem>
                                    <MenuItem value="recuroBulk">Recuro Bulk</MenuItem>
                                    </Select>
                                </FormControl>
                                <CardContent sx={{ position: 'relative' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Button
                                          variant="contained"
                                          component="label"
                                          sx={{ marginRight: 2, backgroundColor: '#646db5' }}
                                        >
                                        Select File
                                        <input
                                          ref={inputImport}
                                          type="file"
                                          onChange={handleFile}
                                          hidden
                                        />
                                        </Button>
                                        { file && (
                                            <Typography color="textPrimary" variant="body1">
                                                {file.name}
                                            </Typography>
                                        )}
                                    </Box>
                                    { (alert.status || alert.message)
                                    ? (
                                        <GroupAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
                                    )
                                    : undefined}
                                </CardContent>
                                </Grid>
                            </Grid>

                        { (file && !isLoadingImport) && (
                            <Box>
                                <Divider />
                                <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={handleImport}
                                    >
                                        Import
                                    </Button>
                                </Box>
                            </Box>
                        )}
                        </Paper>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default GroupImport;
