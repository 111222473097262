import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';

const BrokerGroupListResults = ({ groups }) => (
    <Card>
        <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 80 }}>
                                Id
                            </TableCell>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups.map((group) => (
                            <TableRow key={group.id}>
                                <TableCell sx={{ width: 80 }}>
                                    {group.id}
                                </TableCell>
                                <TableCell>
                                    {group.name}
                                </TableCell>
                                <TableCell>
                                    {!group.isActived ? 'Paused' : 'Actived'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </PerfectScrollbar>
    </Card>
);

BrokerGroupListResults.propTypes = {
    groups: PropTypes.array.isRequired
};

export default BrokerGroupListResults;
