import {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef
} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Container,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Button,
    Modal,
    Typography,
    Avatar,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    TableFooter,
    TableContainer,
    Checkbox,
    CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { red } from '@material-ui/core/colors';
import { v4 as uuidv4 } from 'uuid';
import { Link as RouterLink } from 'react-router-dom';
import getInitials from '../../utils/getInitials';
import getAvatar from '../../utils/getAvatar';
import {
    usePost,
    useHttpDelete
} from '../../request';
import { useConfirm } from '../confirm';
import { useAlert } from '../alert';
import CustomerCheckmark from '../customer/CustomerCheckmark';

const User = ({
    user,
    selectedUser,
    statusVurvId,
    handleSelectOne,
    handleRemoveMember,
    newBenefits,
    recuro,
}) => useMemo(() => {
    const {
        user_login,
        user_last_name,
        user_first_name,
        user_vurv_id,
        user_email,
        user_phone,
        user_id,
        id
    } = user;

    return (
        <TableRow hover key={uuidv4()} selected={selectedUser}>
            <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedUser}
                  onChange={(event) => handleSelectOne(event, user.id)}
                  value="true"
                />
            </TableCell>
            <TableCell>
                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <Avatar src={getAvatar(user)} sx={{ mr: 2 }}>
                        {getInitials(user_login || `${user_last_name} ${user_first_name}`)}
                    </Avatar>
                    <Typography color="textPrimary" variant="body1">
                        {user_login || `${user_last_name} ${user_first_name}`}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <div>{user.user_vurv_id}</div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
                    <CustomerCheckmark
                      checked={statusVurvId}
                      color={statusVurvId ? 'green' : 'grey'}
                      title="Vurv"
                    />
                    <CustomerCheckmark
                      checked={newBenefits}
                      color={newBenefits ? 'black' : 'grey'}
                      title="NewBenefits"
                    />
                    <CustomerCheckmark
                      checked={recuro}
                      color={recuro ? 'indigo' : 'grey'}
                      title="Requro"
                    />
                </div>
            </Box>
            </TableCell>
            <TableCell>
                {user_email}
            </TableCell>
            <TableCell>
                {user_phone}
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <RouterLink to={`/app/customer/${user_id}`}>
                        <Button color="primary" variant="contained">
                            Info
                        </Button>
                    </RouterLink>
                    <Box sx={{ ml: 2 }}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleRemoveMember(id)}
                        >
                            Remove
                        </Button>
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    )
}, [user, selectedUser, handleSelectOne, statusVurvId, newBenefits, recuro]);

const GroupListMembers = ({
    groupId,
    reload,
    setReload,
    button
}) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [filterName, setFilterName] = useState('');
    const [filterId, setFilterId] = useState('');
    const [filterOnlyActive, setFilterOnlyActive] = useState(false);
    const [filterOnlyDeactive, setFilterOnlyDeactive] = useState(false);
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [vurvIdUsers, setVurvIdUsers] = useState({});
    const [statusUsers, setStatusUsers] = useState({});
    const httpDelete = useHttpDelete();
    const post = usePost();
    const confirm = useConfirm();
    const alert = useAlert();
    const { CancelToken } = axios;
    const source = useRef();

    const loadUsers = async () => {
        if (source.current) {
            source.current.cancel();
        }
        source.current = CancelToken.source();
        setStatusUsers({});
        setIsShowLoader(true);
        try {
            const responseCount = await post('admin/group-members-count', { group_id: groupId });
            if (responseCount.result !== 'success') {
                throw new Error(responseCount);
            }
            setCount(responseCount.members);
            const response = await post('admin/group-members-by-pages', {
                filters: {
                    name: filterName,
                    id: filterId,
                    ...(filterOnlyActive ? { onlyActive: filterOnlyActive } : {}),
                    ...(filterOnlyDeactive ? { onlyDeactive: filterOnlyDeactive } : {}),
                },
                group_id: groupId,
                page: page + 1,
                limit
            })
            if (response.result !== 'success') {
                throw new Error(response);
            }
            setIsShowLoader(false);
            setSelectedUsers([]);
            setUsers(response.members);
            response.members.forEach((member) => {
                post('admin/valid-vurv-id', { vurv_id: member.user_vurv_id }, { cancelToken: source.current.token })
                .then((res) => {
                    let vurvValid = false;
                    if (res.result === 'success') {
                        vurvValid = true;
                    }
                    setVurvIdUsers((prev) => ({ ...prev, [member.id]: vurvValid }));
                });
            });
        } catch (e) {
            setIsShowLoader(false);
            console.log(e)
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSelectAll = (event) => {
        let newSelected;

        if (event.target.checked) {
            newSelected = users.map((user) => user.id);
        } else {
            newSelected = [];
        }

        setSelectedUsers(newSelected);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedUsers.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedUsers, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedUsers.slice(1));
        } else if (selectedIndex === selectedUsers.length - 1) {
            newSelected = newSelected.concat(selectedUsers.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedUsers.slice(0, selectedIndex),
                selectedUsers.slice(selectedIndex + 1)
            );
        }
        setSelectedUsers(newSelected);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterName = (event) => {
        setFilterName(event.target.value);
    };

    const handleFilterId = (event) => {
        setFilterId(event.target.value);
    };

    const handleFilterOnlyActive = () => {
        setFilterOnlyActive((prev) => !prev);
        setFilterOnlyDeactive(false);
    };

    const handleFilterOnlyDeactive = () => {
        setFilterOnlyDeactive((prev) => !prev);
        setFilterOnlyActive(false);
    };

    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    useEffect(() => {
        if (reload) {
            setReload(false);
            loadUsers();
        }
    });

    const handleFilter = () => {
        loadUsers();
    }

    const handleRemoveMember = (memberId) => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the member?',
            isDeleting: true,
            onConfirm: () => {
                const requestData = {
                    id: memberId
                };
                httpDelete('admin/group-member', { data: requestData })
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: response.message
                        });
                        return;
                    }
                    loadUsers()
                })
                .catch((e) => console.log(e));
            }
        });
    };

    const removeAllSelected = () => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the all selected members?',
            isDeleting: true,
            onConfirm: () => {
                selectedUsers.map((id, index) => {
                    const requestData = {
                        id
                    };
                    httpDelete('admin/group-member', { data: requestData })
                    .then((response) => {
                        if (response.result === 'error') {
                            alert({
                                status: 'error',
                                message: response.message
                            });
                            return;
                        }
                        if (index === selectedUsers.length - 1) {
                            loadUsers();
                        }
                    })
                    .catch((e) => console.log(e));
                    return id;
                });
            }
        });
    };

    const activateAllSelected = () => {
        confirm({
            title: 'Activate VurvID',
            content: 'Are you sure you want to activate VurvID the all selected members?',
            onConfirm: () => {
                selectedUsers.map((id, index) => {
                    const user = users.find((item) => item.id === id);
                    if (!user) {
                        return id;
                    }
                    const requestData = {
                        vurv_id: user.user_vurv_id
                    };

                    post('admin/manual-activation-vurvid', requestData)
                    .then((response) => {
                        if (response.result === 'success') {
                            alert({
                                status: 'success',
                                message: `Activate VurvID ${user.user_vurv_id}`
                            });
                        } else {
                            alert({
                                status: 'error',
                                message: `Activate VurvID ${user.user_vurv_id}`
                            });
                        }
                        if (index === selectedUsers.length - 1) {
                            loadUsers();
                        }
                    })
                    .catch((e) => console.log(e));
                    return id;
                });
            }
        });
    };

    const deactivateAllSelected = () => {
        confirm({
            title: 'Deactivate VurvID',
            content: 'Are you sure you want to deactivate VurvID the all selected members?',
            onConfirm: () => {
                selectedUsers.map((id, index) => {
                    const user = users.find((item) => item.id === id);
                    if (!user) {
                        return id;
                    }
                    const requestData = {
                        vurv_id: user.user_vurv_id
                    };

                    post('admin/manual-detach-vurvid', requestData)
                    .then((response) => {
                        if (response.result === 'success') {
                            alert({
                                status: 'success',
                                message: `Deactivate VurvID ${user.user_vurv_id}`
                            });
                        } else {
                            alert({
                                status: 'error',
                                message: `Deactivate VurvID ${user.user_vurv_id}`
                            });
                        }
                        if (index === selectedUsers.length - 1) {
                            loadUsers();
                        }
                    })
                    .catch((e) => console.log(e));
                    return id;
                });
            }
        });
    };

    const renderUsers = useMemo(() => {
        if (!users.length) {
            return (
                <TableRow hover key={uuidv4()}>
                    <TableCell />
                    <TableCell>
                        No found
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                </TableRow>
            );
        }
        return users.map((user) => (
            <User
              key={user.id}
              user={user}
              selectedUser={selectedUsers.indexOf(user.id) !== -1}
              statusVurvId={user.user_vurv_id_is_active}
              handleSelectOne={handleSelectOne}
              handleRemoveMember={handleRemoveMember}
              newBenefits={user.in_newbenefits}
              recuro={user.is_recuro}
            />
        ));
    }, [users, vurvIdUsers, selectedUsers]);

    if (isShowLoader) {
        return (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2
              }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ display: 'flex', m: 2 }}>
                <TextField
                  fullWidth
                  label="Name"
                  margin="normal"
                  name="filterName"
                  onChange={handleFilterName}
                  type="text"
                  value={filterName}
                  variant="outlined"
                  sx={{ width: 400, m: 0, mr: 1 }}
                />
                <TextField
                  fullWidth
                  label="Id"
                  margin="normal"
                  name="filterId"
                  onChange={handleFilterId}
                  type="text"
                  value={filterId}
                  variant="outlined"
                  sx={{ width: 300, m: 0, mx: 1 }}
                />
                <Box sx={{ flex: 1, display: 'flex' }}>
                    <Box sx={{ mx: 1, alignItems: 'center', display: 'flex' }}>
                        <Checkbox checked={filterOnlyActive} onChange={handleFilterOnlyActive} />
                        <Typography sx={{ whiteSpace: 'nowrap' }} color="textPrimary" variant="body1">
                            Active VurvID
                        </Typography>
                    </Box>
                    <Box sx={{ mx: 1, alignItems: 'center', display: 'flex' }}>
                        <Checkbox checked={filterOnlyDeactive} onChange={handleFilterOnlyDeactive} />
                        <Typography sx={{ whiteSpace: 'nowrap' }} color="textPrimary" variant="body1">
                            Deactive VurvID
                        </Typography>
                    </Box>
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleFilter}
                  sx={{ alignSelf: 'center' }}
                >
                    Filter
                </Button>
            </Box>
            <Divider />
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
                    { selectedUsers.length > 0 && (
                        <>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={removeAllSelected}
                            >
                                Remove
                            </Button>
                            <Box sx={{ ml: 2 }}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={activateAllSelected}
                                >
                                    Activate VurvID
                                </Button>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={deactivateAllSelected}
                                >
                                    Deactivate VurvID
                                </Button>
                            </Box>
                        </>
                    )}
                    <Box sx={{ ml: 2 }}>
                        {button}
                    </Box>
                </Box>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedUsers.length === users.length}
                                  color="primary"
                                  indeterminate={
                                    selectedUsers.length > 0
                                    && selectedUsers.length < users.length
                                  }
                                  onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                VurvID
                            </TableCell>
                            <TableCell>
                                Email
                            </TableCell>
                            <TableCell>
                                Phone
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {renderUsers}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                              sx={{ border: 0 }}
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={5}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </CardContent>
        </>
    );
};

GroupListMembers.propTypes = {
    groupId: PropTypes.number.isRequired,
    reload: PropTypes.bool.isRequired,
    setReload: PropTypes.func.isRequired,
    button: PropTypes.element
};

export default GroupListMembers;
