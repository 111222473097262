import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    TableCell,
    TableRow,
    Button,
    Table,
    TableHead,
    TableBody
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const UserList = function ({ users }) {
    const renderUsers = useMemo(() => {
        console.log(users)
        if (!users.length) {
            return (
                <TableRow hover key="no-users">
                    <TableCell colSpan={6}>
                        No users found
                    </TableCell>
                </TableRow>
            );
        }

        return users.map((user) => (
            <TableRow hover key={user.id}>
                <TableCell>
                    {user.user_id}
                </TableCell>
                <TableCell>
                    {user.nickname}
                </TableCell>
                <TableCell>
                    {user.user_vurv_id}
                </TableCell>
                <TableCell>
                    {user.email}
                </TableCell>
                <TableCell>
                    {user.phone}
                </TableCell>
                <TableCell>
                    <Box sx={{ display: 'flex' }}>
                        <RouterLink to={`/app/customer/${user.user_id}`}>
                            <Button
                              color="primary"
                              variant="contained"
                            >
                                Info
                            </Button>
                        </RouterLink>
                    </Box>
                </TableCell>
            </TableRow>
        ));
    }, [users]);

    return (
        <Box>
            {users.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>VurvID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderUsers}
                    </TableBody>
                </Table>
            ) : (
                <p>No users found</p>
            )}
        </Box>
    );
};

UserList.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UserList;
