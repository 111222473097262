/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Alert,
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Breadcrumbs,
  Divider,
  Container,
  CircularProgress,
  Button, InputLabel, Select, MenuItem, FormControl, Tab
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { useGet, usePost } from '../request';
import { log10 } from 'chart.js/helpers';
import { serviceKey } from '../enviroment';

const AdvertEdit = function () {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  let [link, setLink] = useState('');
  let [selectedType, setSelectedType] = useState('');
  let [selectedNetworkRedirect, setSelectedNetworkRedirect] = useState('');
  const [tabName, setTabName] = useState('file');
  let [imgDescr, setImgDescr] = useState('');
  let [imgLink, setImgLink] = useState('');
  const [description, setDescription] = useState('');

  const navigate = useNavigate();
  let get = useGet();
  let post = usePost();
  let imgUpload = React.useRef();

  // текст для алертов по умолчанию
  let [successAlertDefaultTxt] = useState('Successfully edited!');
  let [errAlertDefaultTxt] = useState('Error: ad is not edited!');

  // данные переменные привязываются к алерту - в нее выводим текст, который будем в нем показывать
  let [successAlertTxt, setSuccessAlertTxt] = useState(successAlertDefaultTxt);
  let [errAlertTxt, setErrAlertTxt] = useState(errAlertDefaultTxt);

  let [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  // стили для алертов,
  // которые информируют юзера о результате его действий
  let alertsStyle = {
    marginTop: '10px',
    display: 'none'
  };

  let [successEditStyles, setSuccessEditStyles] = useState(alertsStyle);
  let [failedEditStyles, setFailedEditStyles] = useState(alertsStyle);
  let [selectImgStyles, setSelectImgStyles] = useState({
      display: 'inline-block',
      padding: '6px 16px'
  });

  const [types, setTypes] = useState(null);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const truncatedValue = inputValue.slice(0, 255);
    setDescription(truncatedValue);
  };

  function selectedTypeChanged(evt) {
    setSelectedType(evt.target.value);
  }

  function selectedNetworkRedirectChanged(evt) {
      setSelectedNetworkRedirect(evt.target.value);
  }

  function imageUploaded(evt) {
    setImgDescr(evt.target.files[0].name);
  }

  function validateLink() {
      let result = true;

      if (imgLink.trim() == '') {
          setErrAlert('Type an advert image link');
          result = false;
      } else if (selectedType === '') {
          setErrAlert('Type an advert type');
          result = false;
      }

      return result;
  }

  function validateNetworkRedirect() {
    let result = true;

    if (selectedType === selectedNetworkRedirect) {
        setErrAlert('Type and Network Redirect cannot be the same');
        result = false;
    }
    return result;
  }

  function validateDescription() {
    let result = true;

    if (description.length > 255) {
        setErrAlert('description should not exceed 255 characters');
        result = false;
    }
    return result;
  }

  function validateFile() {
      let result = true;

      if (imgUpload.current.value == '' && !imgDescr) {
          setErrAlert('Select an advert image');
          result = false;
      } else if (selectedType === '') {
          setErrAlert('Type an advert type');
          result = false;
      }

      return result;
  }

  function sendData(newImgUrl) {
      setIsLoading(true);
      let editedAd = {
          id: id,
          image_uri: newImgUrl,
          link: link,
          type_id: selectedType,
          network_redirect: selectedNetworkRedirect,
          description: description,
      };

      post(`admin/provider-ad/${editedAd.id}`, editedAd)
        .then(response => {
          setIsLoading(false);
          if (response.result == 'success') {
            setSuccessAlert();
          } else {
            setErrAlert();
          }
        })
        .catch(err => {
          setIsLoading(false);
          console.log("Advert edit error!");
          console.log(new Error(err));
          setErrAlert();
        });
  }

  function editAd() {
    if (tabName === "link") {
      if (validateLink() && validateDescription()) {
          sendData(imgLink);
      }
    }
    if (tabName === "file") {
      if (validateFile() && validateNetworkRedirect()) {
        setSubmitBtnDisabled(true);
        if (imgUpload.current.value != '') {
            let formData = new FormData();
            let file = imgUpload.current.files[0];
            formData.append('file', file);
            formData.append('service_key', serviceKey);

            post('admin/upload-file', formData)
             .then(response => {
                 if (response.result == 'success') {
                   sendData(response.full_uri);
                 } else {
                   setErrAlert();
                 }
             })
             .catch(err => {
               console.log("Advert edit error!");
               console.log(new Error(err));
               setErrAlert();
             });
        } else {
            sendData();
        }
      }
    }
  }

  function setSuccessAlert(txt = successAlertDefaultTxt) {
    setSuccessAlertTxt(txt);
    setSuccessEditStyles({...successEditStyles, display: 'flex'});
    setTimeout(() => {
      setSuccessEditStyles({...successEditStyles, display: 'none'});
      setSuccessAlertTxt(successAlertDefaultTxt);
      setSubmitBtnDisabled(false);
    }, 2000);
  }

  function setErrAlert(txt = errAlertDefaultTxt) {
    setErrAlertTxt(txt);
    setFailedEditStyles({...failedEditStyles, display: 'flex'});
    setTimeout(() => {
      setFailedEditStyles({...failedEditStyles, display: 'none'});
      setErrAlertTxt('Error: data is not sent!');
      setSubmitBtnDisabled(false);
    }, 2000);
  }

  useEffect(() => {
    setIsLoading(true);
    get(`admin/provider-ad/${id}`)
      .then(response => {
          setIsLoading(false);
          if (response.result == 'success') {
            let result = response.providerad[0];
            setLink(result.link);
            setImgLink(result.image_uri);
            setDescription(result.description)

            let imgDesc = result.image_uri.split('/');
            imgDesc = imgDesc[imgDesc.length-1];
            setImgDescr(imgDesc);


            setSelectedType(result.type_id || '');
            setSelectedNetworkRedirect(result.network_redirect || '');
          } else {
            setErrAlert('Error: data has not been downloaded');
          }
      })
      .catch(err => {
          setIsLoading(false);
          console.log('Error!\n');
          console.log(new Error(err));
      });
    get('admin/provider-ad-types')
      .then((response) => {
        if (response.result !== "success") {
          return;
        }
        setTypes(response.types);
      })
      .catch((e) => console.log(e));
  }, []);

  if (!types || isLoading) {
      return (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100
          }}
          >
              <CircularProgress />
          </Box>
      );
  }

  return (
    <>
      <Helmet>
          <title>Edit advertisement | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
          <Container maxWidth={false}>
            <Box sx={{ py: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                    <Box style={{ marginTop: 3, marginRight: 10 }}>
                        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </Box>
                </Box>
                <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                    <RouterLink underline="hover" color="inherit" to="/app/adverts">
                        Advertisements
                    </RouterLink>
                    <p>{id}</p>
                    <p>Edit</p>
                </Breadcrumbs>
            </Box>
            <form>
                <Card>
                    <CardHeader
                      title="Edit advertisement"
                    />
                    <Divider />
                    <CardContent>
                      <TabContext value={tabName}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList onChange={(elem, newValue) => setTabName(newValue)} aria-label="lab API tabs example">
                            <Tab label="File" value="file" />
                            <Tab label="Link" value="link" />
                          </TabList>
                        </Box>
                        <TabPanel sx={{ p: 0 }} value="file">
                          <Button color="primary" variant="contained" sx={{padding: 0, my: 2}}>
                            <label htmlFor="uploadImg" style={selectImgStyles}>
                                Select image
                                <input type="file"
                                       name="uploadAdImg"
                                       id="uploadImg"
                                       accept=".jpg, .jpeg, .png, .svg, .gif"
                                       style={{ display: 'none' }}
                                       ref={imgUpload}
                                       onChange={imageUploaded}
                                />
                            </label>
                          </Button>
                          <span style={{marginLeft: '7px', fontFamily: 'Roboto, Helvetica, sans-serif'}}>{imgDescr}</span>
                        </TabPanel>
                        <TabPanel sx={{ p: 0 }} value="link">
                          <TextField
                            fullWidth
                            label="Image link"
                            margin="normal"
                            name="link"
                            type="text"
                            value={imgLink}
                            onChange={evt => setImgLink(evt.target.value)}
                            variant="outlined"
                          />
                        </TabPanel>
                      </TabContext>
                      <TextField
                        fullWidth
                        label="Link"
                        margin="normal"
                        name="link"
                        type="text"
                        variant="outlined"
                        value={link}
                        onChange={evt => setLink(evt.target.value)}
                      />
                      <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                        <InputLabel id="typeLabel">Type</InputLabel>
                        <Select
                          labelId="typeLabel"
                          name="adType"
                          label="Type"
                          value={selectedType}
                          onChange={selectedTypeChanged}
                        >
                          {types.map(({ id, type_name }) => (
                            <MenuItem key={id} value={id}>{type_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                        <InputLabel id="selectedNetworkRedirectLabel">Network redirect</InputLabel>
                        <Select
                          labelId="selectedNetworkRedirectLabel"
                          name="selectedNetworkRedirect"
                          label="Network redirect"
                          value={selectedNetworkRedirect}
                          onChange={selectedNetworkRedirectChanged}
                        >
                          {types.filter(({ id }) => id != 6).map(({ id, type_name }) => (
                            <MenuItem key={id} value={id}>{type_name}</MenuItem>
                          ))}
                        </Select>
                        <TextField
                          fullWidth
                          label="Description"
                          margin="normal"
                          name="description"
                          onChange={handleChange}
                          type="text"
                          value={description}
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{ maxLength: 255 }}
                        />
                      </FormControl>
                      <Alert severity="success" sx={successEditStyles}>{successAlertTxt}</Alert>
                      <Alert severity="error" sx={failedEditStyles}>{errAlertTxt}</Alert>
                    </CardContent>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                      <Button color="primary" variant="contained" disabled={submitBtnDisabled} onClick={editAd}>
                        Edit
                      </Button>
                    </Box>
                </Card>
            </form>
          </Container>
      </Box>
    </>
  );
};

export default AdvertEdit;
