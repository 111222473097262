/* eslint-disable */
import { useState } from 'react';
import {
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Breadcrumbs,
  CircularProgress
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import { red } from '@material-ui/core/colors';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import CreditCardInput from 'react-credit-card-input';
import { usePut } from '../../request';
import CustomerModalSelectGroup from './CustomerModalSelectGroup';
import CustomerAlert from './CustomerAlert';
import { CustomerSelectCountry } from './CustomerSelectCountry';
import { CustomerSelectState } from './CustomerSelectState';

const CustomerFormCreate = () => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: 'Female',
    phone: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    cardNumber: '',
    expiry: '',
    cvc: ''
  });
  const [visibleModalSelectGroup, setVisibleModalSelectGroup] = useState(false);
  const [group, setGroup] = useState(null);
  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [alert, setAlert] = useState({
    status: '',
    message: ''
  });
  const navigate = useNavigate();
  const put = usePut();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeEmail = (event) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setIsValidateEmail(!!event.target.value.match(reg));
    setValues({
      ...values,
      email: event.target.value
    });
  };

  const submit = () => {
    setIsShowLoader(true);
    const requestData = {
      user_first_name: values.firstName,
      user_last_name: values.lastName,
      user_email: values.email,
      user_gender: values.gender,
      user_phone: values.phone,
      user_address_line1: values.street,
      user_address_city: values.city,
      user_address_state: values.state,
      user_address_country: values.country,
      user_address_postal_code: values.postalCode,
      'card[number]': values.cardNumber,
      'card[exp_month]': values.expiry.split(' / ')[0],
      'card[exp_year]': values.expiry.split(' / ')[1],
      'card[cvc]': values.cvc
    };

    if (group) {
      requestData.insert_into_group_by_id = group.id;
    } else {
      setAlert({
        status: 'error',
        message: 'Select group is required!'
      });
      setIsShowLoader(false);
      return;
    }

    // console.log(requestData);

    put('users/add-user', requestData)
      .then((response) => {
        setIsShowLoader(false);
        setAlert({
          status: response.result,
          message: response.message
        });
      })
      .catch((e) => {
        console.log(e);
        setIsShowLoader(false);
      });
  };

  if (isShowLoader) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 5
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ py: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
          <Box style={{ marginTop: 3, marginRight: 10 }}>
            <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
              Back
            </Button>
          </Box>
        </Box>
        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
          <RouterLink underline="hover" color="inherit" to="/app/customers">
            Customers
          </RouterLink>
          <p>Create</p>
        </Breadcrumbs>
      </Box>
      <form>
        <Card>
          <CardHeader title="Create new customer" />
          <Divider />
          <CardContent sx={{ position: 'relative' }}>
            <TextField
              fullWidth
              label="First name *"
              margin="normal"
              name="firstName"
              onChange={handleChange}
              type="text"
              value={values.firstName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Last name *"
              margin="normal"
              name="lastName"
              onChange={handleChange}
              type="text"
              value={values.lastName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Email *"
              margin="normal"
              name="email"
              onChange={handleChangeEmail}
              type="email"
              value={values.email}
              variant="outlined"
              error={!isValidateEmail}
            />
            <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
              <InputLabel id="genderLabel">Gender</InputLabel>
              <Select
                labelId="genderLabel"
                name="gender"
                value={values.gender}
                label="Gender"
                onChange={handleChange}
              >
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Phone *"
              margin="normal"
              name="phone"
              onChange={handleChange}
              type="tel"
              value={values.phone}
              variant="outlined"
            />
            <CardHeader title="Billing address" />
            <Divider />
            <TextField
              fullWidth
              label="Street address *"
              margin="normal"
              name="street"
              onChange={handleChange}
              type="text"
              value={values.street}
              variant="outlined"
            />
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: 1, pr: 1 }}>
                <TextField
                  fullWidth
                  label="City *"
                  margin="normal"
                  name="city"
                  onChange={handleChange}
                  type="text"
                  value={values.city}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ flex: 1, pl: 1 }}>
                <CustomerSelectState
                  handleChange={handleChange}
                  value={values.state}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: 1, pr: 1 }}>
                <TextField
                  fullWidth
                  label="Postal code *"
                  margin="normal"
                  name="postalCode"
                  onChange={handleChange}
                  type="text"
                  value={values.postalCode}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ flex: 1, pl: 1 }}>
                <CustomerSelectCountry
                  value={values.country}
                  handleChange={handleChange}
                  mt={2}
                  mb={1}
                  blocked={true}
                />
              </Box>
            </Box>
            <Box sx={{ pt: 2, display: 'flex', justifyContent: 'center' }}>
              <CreditCardInput
                fieldStyle={{
                  borderColor: '#c4c4c4',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  padding: 18
                }}
                inputStyle={{ color: '#172b4d' }}
                cardNumberInputProps={{
                  value: values.cardNumber,
                  onChange: handleChange,
                  name: 'cardNumber'
                }}
                dangerTextStyle={{ font: 'inherit' }}
                cardExpiryInputProps={{
                  value: values.expiry,
                  onChange: handleChange,
                  name: 'expiry'
                }}
                cardCVCInputProps={{
                  value: values.cvc,
                  onChange: handleChange,
                  name: 'cvc'
                }}
                fieldClassName="input"
              />
            </Box>
            {group ? (
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  sx={{ mr: 1 }}
                >
                  Selected group:
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  {group.name}
                </Typography>
                <Button sx={{ minWidth: 0 }} onClick={() => setGroup(null)}>
                  <ClearIcon sx={{ color: red[500] }} size={16} />
                </Button>
              </Box>
            ) : (
              <Button
                sx={{ mt: 2 }}
                color="primary"
                variant="contained"
                onClick={() => setVisibleModalSelectGroup(true)}
              >
                Select group
              </Button>
            )}
            <CustomerAlert
              status={alert.status}
              message={alert.message}
              onClose={() => setAlert({ status: '', message: '' })}
            />
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button color="primary" variant="contained" onClick={submit}>
              Create
            </Button>
          </Box>
        </Card>
      </form>
      <CustomerModalSelectGroup
        visible={visibleModalSelectGroup}
        onClose={() => setVisibleModalSelectGroup(false)}
        onSelect={(value) => setGroup(value)}
      />
    </>
  );
};

export default CustomerFormCreate;
