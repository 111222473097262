import { Helmet } from 'react-helmet';
import {
    Box,
    Container
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import VurvIdValidationForm from '../components/vurvIdValidation/VurvIdValidationForm';
import VurvIdValidationFormManualActivation from '../components/vurvIdValidation/VurvIdValidationFormManualActivation';

const VurvIdValidation = () => (
    <>
        <Helmet>
            <title>Vurv id validation | Material Kit</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
            <Container maxWidth={false}>
                <Box sx={{ pt: 3 }}>
                    <VurvIdValidationForm />
                </Box>
            </Container>
        </Box>
    </>
);

export default VurvIdValidation;
