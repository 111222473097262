import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Button,
    Divider,
    TableFooter,
    TablePagination,
    Checkbox,
    Typography,
    Modal,
    Container,
    TableContainer,
    CardHeader,
    CircularProgress
} from '@material-ui/core';
import {
    usePost
} from '../../request';

const CustomerModalSelectGroup = ({ visible, onClose, onSelect }) => {
    const [groups, setGroups] = useState(null);
    const [count, setCount] = useState(0);
    const [name, setName] = useState('');
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const post = usePost();

    const loadGroups = () => {
        const requestData = {
            filters: {
               limit,
               page,
            },
        };

        if (name) {
            requestData.filters.name = name;
        }

        post('admin/groups', requestData)
        .then((response) => {
            if (response.result === 'success') {
                setGroups(response.groups);
                setCount(response.count);
            }
        })
        .catch((e) => console.log(e));
    };

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        loadGroups();
    }, [page, limit]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleFilter = () => {
        loadGroups();
    }

    return (
        <Modal open={visible} onClose={onClose}>
            <Box sx={{ maxWidth: 500, py: 3, margin: 'auto' }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardHeader title="Select group" />
                        <Divider />
                    {!groups ? (
                        <Box sx={{
                            height: 500,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box>
                            <Box sx={{
                                flex: 1,
                                display: 'flex',
                                mx: 2,
                                alignItems: 'center'
                            }}
                            >
                                <TextField
                                  fullWidth
                                  label="Name"
                                  margin="normal"
                                  name="name"
                                  onChange={handleName}
                                  type="text"
                                  value={name}
                                  variant="outlined"
                                  sx={{ mx: 1 }}
                                />
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={handleFilter}
                                  sx={{ mt: 2, mb: 1, minWidth: 90 }}
                                >
                                    Filter
                                </Button>
                            </Box>
                            <Divider />
                            <TableContainer sx={{ maxHeight: '60vh' }}>
                                <Table>
                                    <TableHead sx={{
                                        left: 0,
                                        top: 0,
                                        zIndex: 2,
                                        position: 'sticky',
                                        backgroundColor: '#ffffff'
                                    }}
                                    >
                                        <TableRow>
                                            <TableCell sx={{ width: 80 }}>
                                                Id
                                            </TableCell>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groups.map((group) => (
                                            <TableRow
                                              key={group.id}
                                              hover
                                              sx={{ cursor: 'pointer' }}
                                              onClick={() => {
                                                onSelect(group);
                                                onClose();
                                              }}
                                            >
                                                <TableCell sx={{ width: 80 }}>
                                                    {group.id}
                                                </TableCell>
                                                <TableCell>
                                                    {group.name}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter sx={{
                                        left: 0,
                                        bottom: 0,
                                        zIndex: 2,
                                        position: 'sticky',
                                        backgroundColor: '#ffffff'
                                    }}
                                    >
                                        <TableRow>
                                            <TablePagination
                                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                              colSpan={7}
                                              count={count}
                                              rowsPerPage={limit}
                                              page={page}
                                              onPageChange={handleChangePage}
                                              onRowsPerPageChange={handleChangeLimit}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                    </Card>
                </Container>
            </Box>
        </Modal>
    );
};

CustomerModalSelectGroup.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired
};

export default CustomerModalSelectGroup;
