import { useState } from 'react';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button
} from '@material-ui/core';
import {
    usePost
} from '../../request';
import VurvIdValidationAlert from './VurvIdValidationAlert';

const VurvIdValidationForm = (props) => {
    const [vurvId, setVurvId] = useState('');
    const [alert, setAlert] = useState({
      status: '',
      message: ''
    });
    const post = usePost();

    const handleVurvId = (event) => {
        setVurvId(event.target.value);
    };

    const submit = () => {
      const requestData = {
        vurv_id: vurvId,
      };

      // console.log(requestData);

      post('admin/valid-vurv-id', requestData)
      .then((response) => {
        console.log(response);
        if (response.result === 'success') {
          setAlert({
            status: 'success',
            message: response.message
          });
        } else {
          setAlert({
            status: 'error',
            message: response.message
          });
        }
      })
      .catch((e) => console.log(e));
    };

    return (
      <form>
        <Card>
          <CardHeader title="Vurv id validation" />
          <Divider />
          <CardContent>
              <TextField
                fullWidth
                label="Vurv id"
                margin="normal"
                name="vurvId"
                onChange={handleVurvId}
                type="text"
                value={vurvId}
                variant="outlined"
              />
              <VurvIdValidationAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={submit}
              >
                  Validate
              </Button>
          </Box>
        </Card>
      </form>
    );
};

export default VurvIdValidationForm;
