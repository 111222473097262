/* eslint-disable */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  Breadcrumbs,
  CircularProgress,
  CardHeader,
  CardActions
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/Clear';
import { red, blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import CustomerFormEdit from '../components/customer/CustomerFormEdit';
import CustomerSetPassForm from '../components/customer/CustomerSetPassForm';
import { useGet, usePost, useHttpDelete, usePut } from '../request';
import { uploadURL } from '../enviroment';
import getInitials from '../utils/getInitials';
import getAvatar from '../utils/getAvatar';
import { useConfirm } from '../components/confirm';
import { useAlert } from '../components/alert';
import UserList from '../components/UserList';
import CustomerModalAddMember from '../components/customer/CustomerModalAddMember';
import { AddFamalyMembers } from 'src/components/customer/AddFamalyMembers';

const useStyles = makeStyles({
  wrap: {
    flexDirection: 'row',
    display: 'flex',
    padding: 10,
    height: '55px'
  },
  wrapAvatar: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: 10
  },
  wrapInner: {
    flexDirection: 'row',
    display: 'flex'
  },
  label: {
    width: 110,
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    lineHeight: 1.2,
    alignItems: 'center'
  },
  title: {
    fontSize: 24,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    padding: 10
  },
  subtitle: {
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    padding: 10
  },
  text: {
    fontSize: 18,
    fontFamily: 'Roboto'
  },
  textMessage: {
    fontSize: 14,
    fontFamily: 'Roboto'
  },
  panelRemoved: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#f4433680'
  },
  panel: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: 10,
    '@media (max-width:950px)': {
      gridTemplateColumns: '1fr 1fr'
    },
    '@media (max-width:650px)': {
      gridTemplateColumns: '1fr'
    }
  },
  panelBlock: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#5664d21c',
    width: '100%',
    height: '160px',
    borderRadius: '15px'
  },
  status: {
    display: 'flex',
    position: 'static',
    marginTop: '2px'
  },
  panelText: {
    fontSize: 14,
    fontFamily: 'Roboto',
    lineHeight: 1.2,
    position: 'static',
    marginTop: '2px'
  },
  panelSubtext: {
    color: '#d0d0d0',
    fontSize: '11px',
    fontFamily: 'Roboto',
    lineHeight: 1.3,
    marginLeft: '5px'
  },
  panelTitle: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    padding: 10
  },
  freeUserBadge: {
    backgroundColor: '#f44336',
    color: '#fff',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
    textAlign: 'center'
  }
});

const Customer = () => {
  const { id } = useParams();
  const [reloadCounter, setReloadCounter] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [newBenefits, setNewBenefits] = useState(null);
  const [recuro, setRecuro] = useState(null);
  const [isInStripe, setIsInStripe] = useState(null);
  const [isInCareington, setIsInCareington] = useState(null);
  const styles = useStyles();
  const get = useGet();
  const post = usePost();
  const put = usePut();
  const navigate = useNavigate();
  const httpDelete = useHttpDelete();
  const confirm = useConfirm();
  const alert = useAlert();
  const [isFamily, setIsFamily] = useState();

  const deleteUser = () => {
    confirm({
      title: 'Deleting',
      content: 'Are you sure you want to delete the user?',
      isDeleting: true,
      onConfirm: () => {
        httpDelete(`users/${id}`)
          .then((response) => {
            if (response.result === 'error') {
              alert({
                status: 'error',
                message: response.message
              });
              return;
            }
            navigate(-1);
          })
          .catch((e) => console.log(e));
      }
    });
  };

  const addMember = (user_email) => {
    const requestData = user_email;

    post('user/add-family-member', requestData)
      .then((response) => {
        if (response.result === 'error') {
          alert({
            status: 'error',
            message: response.message
          });
          return;
        }
        Customer();
      })
      .catch((e) => console.log(e));
  };

  const recoverUser = () => {
    confirm({
      title: 'Recover',
      content:
        'Are you sure you want to recover the customer?He already was dropped from Group.',
      isDeleting: true,
      onConfirm: () => {
        post(`admin/recover-customer/${id}`)
          .then((response) => {
            if (response.result === 'error') {
              alert({
                status: 'error',
                message: response.message
              });
            }
          })
          .catch((e) => console.log(e));
      }
    });
  };

  const resendWelcomeEmail = () => {
    confirm({
      title: 'Welcome Email',
      content: 'Are you sure you want to resend welcome email?',
      onConfirm: () => {
        put(`admin/users/resend-welcome-email/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              setReloadCounter(0);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
  };
  /* Carrington Rsync //
    const rsyncCeringtonUser = () => {
        confirm({
            title: 'Synchronization',
            content: 'Are you sure you want to sync the user in cerington?',
            isDeleting: true,
            onConfirm: () => {
                post(`users/rsync-careington/${id}`)
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: 'Unknown error'
                        });
                        return;
                    }
                    alert({
                        status: 'success',
                        message: 'Successfully'
                    });
                    setCustomer(null);
                    setIsInStripe(null);
                    setIsInCareington(null);
                    setReloadCounter((prev) => prev + 1);
                })
                .catch((e) => console.log(e));
            }
        });
    }; */

  useEffect(() => {
    get(`get-user/${id}`)
      .then((response) => {
        if (response.result !== 'success') {
          return;
        }
        setCustomer(response.user);
        console.log(response.user);
        if (response.user.user_stripe_id) {
          post(`user/is-in-stripe/${id}`)
            .then((res) => {
              if (res.result !== 'success') {
                return;
              }
              setIsInStripe(res.isInStripe);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        /*
        // Careington
        post(`user/is-in-careington/${id}`)
            .then((res) => {
              if (res.result !== 'success') {
                return;
              }
              setIsInCareington(res.isCareington);
            })
            .catch((err) => {
              console.log(err);
            })
        */
        // New Benefits
        post(`admin/newbenefits/is-member/${id}`)
          .then((res) => {
            if (res.result !== 'success') {
              return;
            }
            setNewBenefits(res.isMember);
          })
          .catch((err) => {
            console.log(err);
          });

        // Recuro
        post(`admin/recuro/is-member/${id}`)
          .then((res) => {
            if (res.result !== 'success') {
              return;
            }
            setRecuro(res.isMember);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((e) => console.log(e));

    get(`admin/user/get-family-members/${id}`)
      .then((res) => {
        if (res.result !== 'success') {
          return;
        }
        setIsFamily(res.familyMembersList);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(isFamily)
  }, [reloadCounter, id]);

  if (!customer) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 100
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const isFreeUser =
    customer && 'is_free_user' in customer && customer.is_free_user === 1;
  const isNotFreeUserConfirmed =
    customer &&
    'free_user_confirmed' in customer &&
    !customer.free_user_confirmed;

  return (
    <>
      <Helmet>
        <title>Customer | Material Kit</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
              <Box
                sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}
              >
                {customer.soft_delete === 0 && (
                  <Box sx={{ mr: 1 }}>
                    <Button
                      color="success"
                      variant="contained"
                      onClick={() => resendWelcomeEmail()}
                    >
                      Resend Welcome Email
                    </Button>
                  </Box>
                )}
                {customer.user_stripe_id && (
                  <Box sx={{ ml: 1, mr: 1 }}>
                    <RouterLink to={`/app/customer/${id}/customerProducts`}>
                      <Button color="primary" variant="contained">
                        Products
                      </Button>
                    </RouterLink>
                  </Box>
                )}
                <RouterLink to={`/app/customer/${id}/history`}>
                  <Button color="primary" variant="contained">
                    History
                  </Button>
                </RouterLink>
                {customer.user_stripe_id && (
                  <Box sx={{ ml: 1 }}>
                    <RouterLink to={`/app/customer/${id}/payments-history`}>
                      <Button color="primary" variant="contained">
                        Payments History
                      </Button>
                    </RouterLink>
                  </Box>
                )}
                {customer.soft_delete === 1 && (
                  <Box sx={{ ml: 1 }}>
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => recoverUser()}
                    >
                      Recover
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <RouterLink underline="hover" color="inherit" to="/app/customers">
                Customers
              </RouterLink>
              <p>{`${customer.user_first_name} ${customer.user_last_name}`}</p>
            </Breadcrumbs>
          </Box>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <div className={styles.wrapAvatar}>
                <Avatar
                  src={getAvatar(customer)}
                  sx={{ width: 200, height: 200, mr: 2 }}
                >
                  {getInitials(
                    customer.user_login
                      ? customer.user_login
                      : `${customer.user_last_name} ${customer.user_first_name}`
                  )}
                </Avatar>
                <div>
                  <div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>First name:</div>
                      <div className={styles.text}>
                        {customer.user_first_name || '---'}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Last name:</div>
                      <div className={styles.text}>
                        {customer.user_last_name || '---'}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Email:</div>
                      <div className={styles.text}>
                        {customer.user_email || '---'}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Phone:</div>
                      <div className={styles.text}>
                        {customer.user_phone || '---'}
                      </div>
                    </div>
                    {isFreeUser && (
                      <div className={styles.freeUserBadge}>
                        <p>
                          {isNotFreeUserConfirmed
                            ? 'User has not complete a registration'
                            : 'Free user'}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!!customer.soft_delete && (
                <div className={styles.panelRemoved}>
                  <div className={styles.subtitle}>
                    User deleted in the system
                  </div>
                </div>
              )}
              {/* Stripe */}
              <div className={styles.panel}>
                {customer.user_stripe_id && (
                  <div
                    className={styles.panelBlock}
                    style={{ background: '#635bff', color: '#fff' }}
                  >
                    <div className={styles.panelTitle}>Stripe</div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>StripeId:</div>
                      <div className={styles.panelText}>
                        {customer.user_stripe_id}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Sync:</div>
                      {isInStripe === null && <CircularProgress size={20} />}
                      {isInStripe ? (
                        <div
                          className={styles.status}
                          style={{ color: '#00ff00', fontWeight: 'bold' }}
                        >
                          <DoneIcon
                            sx={{ marginRight: '5px', fontSize: '1.2rem' }}
                          />
                          <div className={styles.panelText}>Success</div>
                        </div>
                      ) : (
                        isInStripe !== null && (
                          <div
                            className={styles.status}
                            style={{ color: red[500], fontWeight: 'bold' }}
                          >
                            <ClearIcon
                              sx={{
                                marginRight: '5px',
                                marginBottom: '3px',
                                fontSize: '1.2rem'
                              }}
                            />
                            <div className={styles.panelText}>Failed</div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                {/* Сareington
                                <div className={styles.panelBlock} style={{ background: '#55aed3', color: '#fff' }}>
                                    <div className={styles.panelTitle}>
                                        Сareington
                                    </div>
                                    {customer?.group_and_bokers && (
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Group:
                                        </div>
                                        <div className={styles.panelText} style={{ display: 'flex' }}>
                                            {customer.group_and_bokers?.name || '---'}
                                            {customer.group_and_bokers.isActived ? (
                                                <p className={styles.panelSubtext}>(Actived)</p>
                                            ) : (
                                                <p className={styles.panelSubtext}>(Paused)</p>
                                            )}
                                        </div>
                                    </div>
                                     )}
                                    <div className={styles.wrap} style={{ marginTop: customer?.group_and_bokers ? '0' : '20px' }}>
                                        <div className={styles.label}>
                                            Sync:
                                        </div>
                                        {isInCareington === null && (
                                            <CircularProgress size={20} />
                                        )}
                                        {isInCareington ? (
                                            <div className={styles.status} style={{ color: '#00ff00', fontWeight: 'bold' }}>
                                                <DoneIcon sx={{ marginRight: '5px', fontSize: '1.2rem' }} />
                                                <div className={styles.panelText}>
                                                    Success
                                                </div>
                                            </div>
                                        ) : isInCareington !== null && (
                                            <div className={styles.status} style={{ color: red[500], fontWeight: 'bold' }}>
                                                <ClearIcon sx={{ marginBottom: '3px', fontSize: '1.2rem' }} />
                                                <div className={styles.panelText}>
                                                    Failed
                                                </div>
                                                {customer?.group_and_bokers?.isActived && (
                                                    <Button onClick={rsyncCeringtonUser} color="primary" variant="contained" size="small" sx={{ ml: 2 }} style={{ bottom: '6px' }}>
                                                        Rsync
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div> */}
                {/* Vurv */}
                {customer.user_vurv_id && (
                  <div
                    className={styles.panelBlock}
                    style={{ background: '#8dacb8', color: '#fff' }}
                  >
                    <div className={styles.panelTitle}>Vurv</div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>VurvID:</div>
                      <div className={styles.panelText}>
                        {customer.user_vurv_id}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Activated:</div>
                      {customer.user_vurv_id_is_active ? (
                        <div
                          className={styles.status}
                          style={{ color: '#00ff00', fontWeight: 'bold' }}
                        >
                          <DoneIcon
                            sx={{ marginRight: '5px', fontSize: '1.2rem' }}
                          />
                          <div className={styles.panelText}>Is Active</div>
                        </div>
                      ) : (
                        <div
                          className={styles.status}
                          style={{ color: red[500], fontWeight: 'bold' }}
                        >
                          <ClearIcon
                            sx={{
                              marginRight: '5px',
                              marginBottom: '3px',
                              fontSize: '1.2rem'
                            }}
                          />
                          <div className={styles.panelText}>Disabled</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* newBenefits */}
                <div
                  className={styles.panelBlock}
                  style={{ background: '#4f4f4f', color: '#fff' }}
                >
                  <div className={styles.panelTitle}>NewBenefits</div>
                  <div className={styles.wrap} style={{ marginTop: '20px' }}>
                    <div className={styles.label}>Activated:</div>
                    {newBenefits === null && <CircularProgress size={20} />}
                    {newBenefits ? (
                      <div
                        className={styles.status}
                        style={{ color: '#00ff00', fontWeight: 'bold' }}
                      >
                        <DoneIcon
                          sx={{ marginRight: '5px', fontSize: '1.2rem' }}
                        />
                        <div className={styles.panelText}>Is Active</div>
                      </div>
                    ) : (
                      newBenefits !== null && (
                        <div
                          className={styles.status}
                          style={{ color: 'red', fontWeight: 'bold' }}
                        >
                          <ClearIcon
                            sx={{
                              marginRight: '5px',
                              marginBottom: '3px',
                              fontSize: '1.2rem'
                            }}
                          />
                          <div className={styles.panelText}>Disabled</div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                {/* Recuro */}
                <div
                  className={styles.panelBlock}
                  style={{ background: '#3f51b5', color: '#fff' }}
                >
                  <div className={styles.panelTitle}>Recuro</div>
                  <div className={styles.wrap}>
                    <div className={styles.label}>Recuro ID:</div>
                    <div className={styles.panelText}>
                      {customer.is_recuro ? customer.is_recuro : '---'}
                    </div>
                  </div>
                  <div className={styles.wrap}>
                    <div className={styles.label}>Activated:</div>
                    {recuro === null && <CircularProgress size={20} />}
                    {recuro ? (
                      <div
                        className={styles.status}
                        style={{ color: '#00ff00', fontWeight: 'bold' }}
                      >
                        <DoneIcon
                          sx={{ marginRight: '5px', fontSize: '1.2rem' }}
                        />
                        <div className={styles.panelText}>Is Active</div>
                      </div>
                    ) : (
                      recuro !== null && (
                        <div
                          className={styles.status}
                          style={{ color: red[500], fontWeight: 'bold' }}
                        >
                          <ClearIcon
                            sx={{
                              marginRight: '5px',
                              marginBottom: '3px',
                              fontSize: '1.2rem'
                            }}
                          />
                          <div className={styles.panelText}>Disabled</div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.title}>Subscribe info</div>
              <div className={styles.wrap}>
                <div className={styles.label}>Expired date:</div>
                <div className={styles.text}>
                  {customer.expired_date || '---'}
                </div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.label}>Name:</div>
                <div className={styles.text}>
                  {customer.subscription?.name || '---'}
                </div>
              </div>
              <div className={styles.title}>Customer data</div>
              {customer?.group_and_bokers?.name && (
                <div className={styles.wrap}>
                  <div className={styles.label}>Group:</div>
                  <div className={styles.text}>
                    {customer.group_and_bokers.name}
                  </div>
                </div>
              )}
              {customer?.group_and_bokers?.broker_name && (
                <div className={styles.wrap}>
                  <div className={styles.label}>Boker:</div>
                  <div className={styles.text}>
                    {customer.group_and_bokers.broker_name}
                  </div>
                </div>
              )}
              <div className={styles.wrap}>
                <div className={styles.label}>City:</div>
                <div className={styles.text}>
                  {customer.user_address_city || '---'}
                </div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.label}>Country:</div>
                <div className={styles.text}>
                  {customer.user_address_country || '---'}
                </div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.label}>Street:</div>
                <div className={styles.text}>
                  {customer.user_address_line1 || '---'}
                </div>
              </div>
              {customer.user_address_line2 && (
                <div className={styles.wrap}>
                  <div className={styles.label}>Street:</div>
                  <div className={styles.text}>
                    {customer.user_address_line2}
                  </div>
                </div>
              )}
              <div className={styles.wrap}>
                <div className={styles.label}>Postal code:</div>
                <div className={styles.text}>
                  {customer.user_address_postal_code || '---'}
                </div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.label}>State:</div>
                <div className={styles.text}>
                  {customer.user_address_state || '---'}
                </div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.label}>Gender:</div>
                <div className={styles.text}>
                  {customer.user_gender || '---'}
                </div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.label}>Login:</div>
                <div className={styles.text}>
                  {customer.user_login || '---'}
                </div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.label}>Nickname:</div>
                <div className={styles.text}>
                  {customer.user_nickname || '---'}
                </div>
              </div>
              <div className={styles.wrap}>
                <div className={styles.label}>Registered:</div>
                <div className={styles.text}>
                  {customer.user_registered
                    ? moment(customer.user_registered).format('DD/MM/YYYY')
                    : '---'}
                </div>
              </div>
            </CardContent>
            {!customer.soft_delete && (
              <>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <RouterLink to={`/app/customer/edit/${id}`}>
                    <Button color="primary" variant="contained">
                      Edit
                    </Button>
                  </RouterLink>
                  {id > 1 && (
                    <Box sx={{ ml: 2 }}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={deleteUser}
                      >
                        Delete
                      </Button>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Card>
        </Container>
        <Box sx={{ backgroundColor: 'background.default', marginTop: 2 }}>
          <Container maxWidth={false} style={{ paddingBottom: '25px' }}>
            <Card sx={{ mb: 2 }}>
              <CardHeader
                title="Family members"
                titleTypographyProps={{ variant: 'h5' }}
                component="h2"
              />
              <Divider />
              <CardActions>
                <Box sx={{ flexGrow: 1 }} />
                <CustomerModalAddMember addMember={addMember}>
                  {({ openModal }) => (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={openModal}
                      style={{ display: 'none' }}
                    >
                      Add member
                    </Button>
                  )}
                </CustomerModalAddMember>
              </CardActions>
              <CardContent>
                {isFamily && isFamily.length > 0 ? (
                  <UserList users={isFamily} />
                ) : (
                  <div className={styles.textMessage}>
                    Customer don&apos;t have invited family members
                  </div>
                )}
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  pt: 2,
                  pb: 2
                }}
              >
                <AddFamalyMembers />
              </Box>
            </Card>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Customer;
