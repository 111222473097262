import moment from 'moment';
import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useGet } from '../../request';

const orders = [
  {
    id: uuid(),
    ref: 'CDD1049',
    amount: 30.5,
    customer: {
      name: 'Ekaterina Tankova'
    },
    createdAt: 1555016400000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1048',
    amount: 25.1,
    customer: {
      name: 'Cao Yu'
    },
    createdAt: 1555016400000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1047',
    amount: 10.99,
    customer: {
      name: 'Alexa Richardson'
    },
    createdAt: 1554930000000,
    status: 'refunded'
  },
  {
    id: uuid(),
    ref: 'CDD1046',
    amount: 96.43,
    customer: {
      name: 'Anje Keizer'
    },
    createdAt: 1554757200000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1045',
    amount: 32.54,
    customer: {
      name: 'Clarke Gillebert'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1044',
    amount: 16.76,
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  }
];

const GroupExpired = (props) => {
  const get = useGet();
  const [data, setData] = useState({
    data: [
      {
        id: uuid(),
        ref: 'CDD1049',
        amount: 30.5,
        customer: {
          name: 'Ekaterina Tankova'
        },
        createdAt: 1555016400000,
        status: 'pending'
      },
      {
        id: uuid(),
        ref: 'CDD1048',
        amount: 25.1,
        customer: {
          name: 'Cao Yu'
        },
        createdAt: 1555016400000,
        status: 'delivered'
      }
    ]
  });

  useEffect(() => {
    get('admin/group/get-expired')
      .then((response) => {
        if (response.result !== 'success') {
          return;
        }

        let newArr = [];

        response.groups.forEach((item) => {
          newArr.push({
            id: item.id,
            broker_name: item.broker_name ? item.broker_name : '',
            ref: item.name ? item.name : '',
            members: item.members.id,
            expiration_data: item.expirtaion_date ? item.expirtaion_date : ''
          });
        });

        setData({
          data: newArr
        });
      })
      .catch((e) => console.log(e));
  }, []);

  const getData = function (param) {
    return param ? moment(param).format('DD/MM/YYYY') : '';
  };

  return (
    <Card {...props}>
      <CardHeader title="Group Expiration" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 600 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Broker</TableCell>
                <TableCell>Group Name</TableCell>
                <TableCell>Members</TableCell>
                <TableCell>
                  <TableSortLabel>Expires</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.map((order) => (
                <TableRow hover key={order.id}>
                  <TableCell>{order.broker_name}</TableCell>
                  <TableCell style={{ color: '#5664d2' }}>
                    <RouterLink
                      to={`/app/group/${order.id}`}
                      style={{ color: '#5664d2' }}
                    >
                      {order.ref}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{order.members}</TableCell>
                  <TableCell>{getData(order.expiration_data)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

export default GroupExpired;
