import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button
} from '@material-ui/core';

const ModalEdit = ({
  title,
  isOpen,
  onClose,
  handleSubmit,
  value,
  handleCange,
  name,
  disabled
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(name);
        }}
      >
        <DialogTitle>
          <Typography>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            label={title}
            name={name}
            onChange={handleCange}
            type="text"
            variant="outlined"
            value={value}
            sx={{ width: '100%' }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: '0 20px 20px 20px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button color="primary" onClick={onClose} variant="contained">
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={disabled}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEdit;
