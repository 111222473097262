import { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Breadcrumbs,
    Button,
    Typography,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import EmailTemplateListResults from '../components/email-template/EmailTemplateListResults';
import {
    useGet, useHttpDelete
} from '../request';
import { useConfirm } from '../components/confirm';
import { useAlert } from '../components/alert';

const EmailTemplateList = () => {
    const [emailTemplates, setEmailTemplates] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const get = useGet();
    const httpDelete = useHttpDelete();
    const navigate = useNavigate();
    const alert = useAlert();
    const confirm = useConfirm();

    if (!isLoad) {
        get('email-templates')
        .then((response) => {
            // console.log(response);
            if (response.result !== 'success') {
                setIsLoad(true);
                return;
            }
            setEmailTemplates(response.templates);
            setIsLoad(true);
        })
        .catch((e) => console.log(e));
    }

    const onDelete = (id) => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the template?',
            isDeleting: true,
            onConfirm: () => {
                httpDelete(`email-template/${id}`)
                .then((response) => {
                    if (response.result === 'error') {
                        alert({
                            status: 'error',
                            message: response.message
                        });
                        return;
                    }
                    setIsLoad(false);
                })
                .catch((e) => console.log(e));
            }
        });
    };

    if (!emailTemplates) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Email templates | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Email templates
                            </Typography>
                            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                                <RouterLink to="/app/email-templates/create">
                                    <Button color="primary" variant="contained">
                                        Add email template
                                    </Button>
                                </RouterLink>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Email templates</p>
                        </Breadcrumbs>
                    </Box>
                    <EmailTemplateListResults templates={emailTemplates} onDelete={onDelete} />
                </Container>
            </Box>
        </>
    );
};

export default EmailTemplateList;
