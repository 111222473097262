/* eslint-disable */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { CustomerSelectState } from './CustomerSelectState';
import { CustomerSelectCountry } from './CustomerSelectCountry';
import CustomerAlert from './CustomerAlert';
import { usePut } from 'src/request';

const initValues = {
  firstName: '',
  lastName: '',
  email: '',
  gender: 'Female',
  phone: '',
  street: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'US',
  cardNumber: ''
};

const AddFamalyMembers = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(initValues);
  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const put = usePut();
  const [alert, setAlert] = useState({
    status: '',
    message: ''
  });

  useEffect(() => {
    if (!isOpen) {
      setAlert({
        status: '',
        message: ''
      });
    }
    return () => {
      setAlert({
        status: '',
        message: ''
      });
    };
  }, [isOpen]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeEmail = (event) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setIsValidateEmail(!!event.target.value.match(reg));
    setValues({
      ...values,
      email: event.target.value
    });
  };

  const handleCancelModal = () => {
    setAlert({
      status: '',
      message: ''
    });
    setIsOpen(false);
    setValues(initValues);
  };

  const submit = () => {
    setIsShowLoader(true);
    const requestData = {
      user_first_name: values.firstName,
      user_last_name: values.lastName,
      user_email: values.email,
      user_gender: values.gender,
      user_phone: values.phone,
      user_address_line1: values.street,
      user_address_city: values.city,
      user_address_state: values.state,
      user_address_country: values.country,
      user_address_postal_code: values.postalCode
    };

    put('users/add-user', requestData)
      .then((response) => {
        if (response.result === 'success') {
          handleCancelModal();
          setAlert({
            status: 'success',
            message: response.message
          });
        } else {
          throw response.message;
        }
      })
      .catch((error) => {
        console.error(error);
        setAlert({
          status: 'error',
          message: error
        });
      })
      .finally(() => setIsShowLoader(false));
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card
          sx={{
            backgroundColor: 'white',
            boxShadow: 24,
            borderRadius: 1,
            padding: 1,
            maxWidth: '1500px',
            width: '95%'
          }}
        >
          <CardHeader title="Add family member" />
          <Divider />
          <CardContent sx={{ position: 'relative' }}>
            <TextField
              fullWidth
              label="First name *"
              margin="normal"
              name="firstName"
              onChange={handleChange}
              type="text"
              value={values.firstName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Last name *"
              margin="normal"
              name="lastName"
              onChange={handleChange}
              type="text"
              value={values.lastName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Email *"
              margin="normal"
              name="email"
              onChange={handleChangeEmail}
              type="email"
              value={values.email}
              variant="outlined"
              error={!isValidateEmail}
            />
            <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
              <InputLabel id="genderLabel">Gender</InputLabel>
              <Select
                labelId="genderLabel"
                name="gender"
                value={values.gender}
                label="Gender"
                onChange={handleChange}
              >
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Phone *"
              margin="normal"
              name="phone"
              onChange={handleChange}
              type="tel"
              value={values.phone}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Street address *"
              margin="normal"
              name="street"
              onChange={handleChange}
              type="text"
              value={values.street}
              variant="outlined"
            />
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: 1, pr: 1 }}>
                <TextField
                  fullWidth
                  label="City *"
                  margin="normal"
                  name="city"
                  onChange={handleChange}
                  type="text"
                  value={values.city}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ flex: 1, pl: 1 }}>
                <CustomerSelectState
                  handleChange={handleChange}
                  value={values.state}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: 1, pr: 1 }}>
                <TextField
                  fullWidth
                  label="Postal code *"
                  margin="normal"
                  name="postalCode"
                  onChange={handleChange}
                  type="text"
                  value={values.postalCode}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ flex: 1, pl: 1 }}>
                <CustomerSelectCountry
                  value={values.country}
                  handleChange={handleChange}
                  mt={2}
                  mb={1}
                  blocked={true}
                />
              </Box>
            </Box>
            <CustomerAlert
              status={alert.status}
              message={alert.message}
              onClose={() => setAlert({ status: '', message: '' })}
            />
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2,
              columnGap: 1
            }}
          >
            <Button
              disabled={isShowLoader}
              color="primary"
              variant="contained"
              onClick={submit}
            >
              Add
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleCancelModal}
            >
              Cancel
            </Button>
          </Box>
        </Card>
      </Modal>

      <Button
        color="primary"
        variant="contained"
        onClick={() => setIsOpen(true)}
      >
        Add family member
      </Button>
    </>
  );
};

export { AddFamalyMembers };
