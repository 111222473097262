import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Breadcrumbs,
    Typography,
    Tabs,
    Tab,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import BrokerGroupListResults from '../components/broker/BrokerGroupListResults';
import {
    useGet
} from '../request';

const BrokerDeactiveGroupList = () => {
    const { id } = useParams();
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [groups, setGroups] = useState([]);
    const [broker, setBroker] = useState([]);
    const navigate = useNavigate();
    const get = useGet();

    const loadBroker = () => get(`admin/brokers/${id}`)
    .then((response) => {
        if (response.result !== 'success') {
            return;
        }
        setBroker(response.data);
    });

    const loadGroups = () => get(`admin/brokers/${id}/paused-groups`)
    .then((response) => {
        if (response.result === 'success') {
            setGroups(response.groups);
        }
    });

    useEffect(() => {
        setIsShowLoader(true);
        const p1 = loadBroker();
        const p2 = loadGroups();
        Promise.all([p1, p2])
        .then(() => {
            setIsShowLoader(false);
        })
        .catch((e) => {
            setIsShowLoader(false);
            console.log(e)
        });
    }, []);

    if (isShowLoader) {
        return (
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 5
                }}
            >
                  <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Broker deactive groups | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Broker deactive groups
                            </Typography>

                            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                                <RouterLink to={`/app/broker/group-export/${id}`}>
                                    <Button color="primary" variant="contained">
                                        Export
                                    </Button>
                                </RouterLink>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/brokers">
                                Brokers
                            </RouterLink>
                            <RouterLink underline="hover" color="inherit" to={`/app/broker/${id}`}>
                                {broker.name}
                            </RouterLink>
                            <p>Broker deactive groups</p>
                        </Breadcrumbs>
                    </Box>
                    <BrokerGroupListResults groups={groups} />
                </Container>
            </Box>
        </>
    );
};

export default BrokerDeactiveGroupList;
