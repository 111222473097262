import {
    useState,
    useEffect,
    useMemo,
    useRef
} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Container,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Button,
    Modal,
    Typography,
    Avatar,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    TableFooter,
    CircularProgress,
    TableContainer
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import getInitials from '../../utils/getInitials';
import getAvatar from '../../utils/getAvatar';
import {
    usePost
} from '../../request';

const ListItems = ({ users, onClick }) => users.map((user) => (
    <TableRow hover sx={{ cursor: 'pointer' }} key={uuidv4()} onClick={() => onClick(user.id)}>
        <TableCell sx={{ width: 80 }}>
            {user.id}
        </TableCell>
        <TableCell sx={{ alignItems: 'center', display: 'flex' }}>
            <Avatar src={getAvatar(user)} sx={{ mr: 2 }}>
                {getInitials(user.user_login ? user.user_login : `${user.user_last_name} ${user.user_first_name}`)}
            </Avatar>
            <Typography color="textPrimary" variant="body1">
                {user.user_login ? user.user_login : `${user.user_last_name} ${user.user_first_name}`}
            </Typography>
        </TableCell>
        <TableCell sx={{ width: 80 }}>
            {user.user_email}
        </TableCell>
    </TableRow>
));

ListItems.propTypes = {
    onClick: PropTypes.func,
    users: PropTypes.array
};

ListItems.defaultProps = {
    onClick: () => {},
    users: []
};

const CustomerModalAddMember = ({ addMember, children }) => {
    const isMount = useRef();
    const [visible, setVisible] = useState(false);
    const [users, setUsers] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [filterId, setFilterId] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const post = usePost();

    const loadUsers = () => {
        const requestData = {
            filters: {},
            page,
            limit
        };

        if (filterName) {
            requestData.filters.name = filterName;
        }

        if (filterId) {
            requestData.filters.id = filterId;
        }

        if (filterEmail) {
            requestData.filters.email = filterEmail;
          }

        post('get-users-list', requestData)
        .then((response) => {
            if (!isMount.current) {
                return;
            }
            if (response.result === 'success') {
                setUsers(response.users);
                setCount(response.count);
            }
        })
        .catch((e) => console.log(e));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterName = (event) => {
        setFilterName(event.target.value);
    };

    const handleFilterId = (event) => {
        setFilterId(event.target.value);
    };

    const handleFilterEmail = (event) => {
        setFilterEmail(event.target.value);
      };

    const handleOpenModal = () => {
        setVisible(true);
    };

    const handleCloseModal = () => {
        setVisible(false);
    };

    const handleAddMember = (id) => {
        addMember(id);
        handleCloseModal();
    };

    const handleFilter = () => {
        setIsLoading(true)
        loadUsers();
        setIsLoading(false)
    }

    useEffect(() => {
        isMount.current = true;

        return () => {
            isMount.current = false;
        };
    })

    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    return (
        <>
            {children({ openModal: handleOpenModal })}
            <Modal open={visible} onClose={handleCloseModal}>
                <Box sx={{ maxWidth: 1200, py: 3, margin: 'auto' }}>
                    <Container maxWidth={false}>
                        <Card>
                            <CardHeader title="Add member" />
                            <Divider />
                            <CardContent>
                                <Box sx={{ display: 'flex' }}>
                                    <TextField
                                      fullWidth
                                      label="Id"
                                      margin="normal"
                                      name="filterId"
                                      onChange={handleFilterId}
                                      type="text"
                                      value={filterId}
                                      variant="outlined"
                                      sx={{ width: 250, mx: 1 }}
                                    />
                                    <TextField
                                      fullWidth
                                      label="Name"
                                      margin="normal"
                                      name="filterName"
                                      onChange={handleFilterName}
                                      type="text"
                                      value={filterName}
                                      variant="outlined"
                                      sx={{ width: 800, mx: 1 }}
                                    />
                                    <TextField
                                      fullWidth
                                      label="Email"
                                      margin="normal"
                                      name="filterEmail"
                                      onChange={handleFilterEmail}
                                      type="text"
                                      value={filterEmail}
                                      variant="outlined"
                                      sx={{ width: 800, mx: 1 }}
                                    />
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={handleFilter}
                                      sx={{ mt: 2, mb: 1 }}
                                    >
                                        Filter
                                    </Button>
                                </Box>
                                <TableContainer
                                  sx={{
                                    maxHeight: '60vh'
                                  }}
                                >
                                    <Table>
                                        <TableHead sx={{
                                            left: 0,
                                            top: 0,
                                            zIndex: 2,
                                            position: 'sticky',
                                            backgroundColor: '#ffffff'
                                        }}
                                        >
                                            <TableRow>
                                                <TableCell sx={{ width: 180 }}>
                                                    Id
                                                </TableCell>
                                                <TableCell sx={{ width: 580 }}>
                                                    Name
                                                </TableCell>
                                                <TableCell sx={{ width: 580 }}>
                                                    Email
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {isLoading ? (
                                            <TableBody>
                                                <TableCell sx={{ height: 280 }}>
                                                <Box sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 100
                                                        }}
                                                >
                                                        <CircularProgress />
                                                </Box>
                                                </TableCell>
                                            </TableBody>
                                    ) : (
                                        <TableBody>
                                                {useMemo(() => (<ListItems users={users} onClick={handleAddMember} />), [users])}
                                        </TableBody>
                                        )}
                                        <TableFooter sx={{
                                            left: 0,
                                            bottom: 0,
                                            zIndex: 2,
                                            position: 'sticky',
                                            backgroundColor: '#ffffff'
                                        }}
                                        >
                                            <TableRow>
                                                <TablePagination
                                                  sx={{ border: 0 }}
                                                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                  colSpan={2}
                                                  count={count}
                                                  rowsPerPage={limit}
                                                  page={page}
                                                  onPageChange={handleChangePage}
                                                  onRowsPerPageChange={handleChangeLimit}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Container>
                </Box>
            </Modal>
        </>
    );
};

CustomerModalAddMember.propTypes = {
    addMember: PropTypes.func,
    children: PropTypes.func
};

CustomerModalAddMember.defaultProps = {
    addMember: () => {},
    children: () => {}
};

export default CustomerModalAddMember;
