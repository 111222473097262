import PropTypes from 'prop-types';
import {
  Box,
  Alert
} from '@material-ui/core';

const GroupAlert = ({ status, message, onClose }) => {
  if (!status) {
    return <></>;
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Alert onClose={onClose} severity={status}>{message}</Alert>
    </Box>
  );
}

GroupAlert.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func
};

GroupAlert.defaultProps = {
  status: '',
  message: '',
  onClose: () => {}
};

export default GroupAlert;
