import PropTypes from 'prop-types';
import {
  Box,
  Alert
} from '@material-ui/core';

const CustomerAddFormAlert = ({ status, onClose }) => {
  if (!status) {
    return <></>;
  }

  let localStatus = '';
  let localMessage = '';

  switch (status) {
    case 'success':
      localStatus = 'success';
      localMessage = 'Saved successfully';
    break;
    case 'error':
      localStatus = 'error';
      localMessage = 'Save error';
    break;
    default:
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Alert onClose={onClose} severity={localStatus}>{localMessage}</Alert>
    </Box>
  );
}

CustomerAddFormAlert.propTypes = {
  status: PropTypes.string,
  onClose: PropTypes.func
};

CustomerAddFormAlert.defaultProps = {
  status: '',
  onClose: () => {}
};

export default CustomerAddFormAlert;
