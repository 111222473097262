/* eslint-disable */
import React, { useState , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Typography,
    Breadcrumbs,
    Button,
    CircularProgress
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useGet, usePost } from "../request";
import ProductsTable from '../components/ProductsTable';

const Products = function () {
    const post = usePost();
    const get = useGet();
    const [isShowLoader, setIsShowLoader] = useState(false);
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);

    const loadProducts = () => {
        setIsShowLoader(true);
        get('api/strapi/get-products')
        .then((response) => {
            if (response.result === 'success') {
                setProducts(response.products);
            }
        })
        .catch((e) => console.log(e))
        .finally(() => setIsShowLoader(false));
    };

    useEffect(() => {
        loadProducts();
    }, []);

    return (
        <>
            <Helmet>
                <title>Products | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false} sx={{ pb: 2 }}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                Products
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>Products</p>
                        </Breadcrumbs>
                    </Box>
                    { isShowLoader ? (
                        <Box sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 5
                          }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <ProductsTable products={products} />
                    )}
                </Container>
                    { isShowLoader ? (
                        <Box></Box>
                    ) : (
                        <Container>
                            Is configured - all products must be setup in subscription.
                        </Container>
                    )}
            </Box>
        </>
    );
};

export default Products;
