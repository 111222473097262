import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const BrokerListResults = ({ brokers, deleteBroker, ...rest }) => (
    <Card {...rest}>
        <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 80 }}>
                                Id
                            </TableCell>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {brokers.map((broker) => (
                            <TableRow key={broker.id}>
                                <TableCell sx={{ width: 80 }}>
                                    {broker.id}
                                </TableCell>
                                <TableCell>
                                    {broker.name}
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <RouterLink to={`/app/broker/${broker.id}`}>
                                            <Button color="primary" variant="contained">
                                                Info
                                            </Button>
                                        </RouterLink>
                                        <Box sx={{ ml: 2 }}>
                                            <RouterLink to={`/app/broker/edit/${broker.id}`}>
                                                <Button color="primary" variant="contained">
                                                    Edit
                                                </Button>
                                            </RouterLink>
                                        </Box>
                                        <Box sx={{ ml: 2 }}>
                                            <Button color="primary" variant="contained" onClick={() => deleteBroker(broker.id)}>
                                                Delete
                                            </Button>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </PerfectScrollbar>
    </Card>
);

BrokerListResults.propTypes = {
    brokers: PropTypes.array.isRequired,
    deleteBroker: PropTypes.func.isRequired,
};

export default BrokerListResults;
