import { useState } from 'react';
import {
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Breadcrumbs,
  Button,
  CircularProgress
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  usePut
} from '../../request';
import BrokerAlert from './BrokerAlert';

const BrokerFormCreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [generalContact, setGeneralContact] = useState('');
    const [comments, setComments] = useState('');
    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });
    const navigate = useNavigate();
    const put = usePut();

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleCity = (event) => {
        setCity(event.target.value);
    };

    const handleState = (event) => {
        setState(event.target.value);
    };

    const handlePostalCode = (event) => {
        setPostalCode(event.target.value);
    };

    const handlePhone = (event) => {
        setPhone(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleGeneralContact = (event) => {
        setGeneralContact(event.target.value);
    };

    const handleComments = (event) => {
        setComments(event.target.value);
    };

    const submit = () => {
        setIsLoading(true);
        const requestData = {
            name,
            city,
            state,
            postal_code: postalCode,
            phone,
            email,
            general_contact: generalContact,
            comments
        };

        put('admin/brokers', requestData)
        .then((response) => {
            setIsLoading(false);
            if (response.result === 'success') {
                setAlert({
                    status: response.result,
                    message: 'Successfully'
                });
                return
            }
            setAlert({
                status: response.result,
                message: response.message
            });
        })
        .catch((e) => {
            setIsLoading(false);
            console.log(e);
        });
    };

    if (isLoading) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 100
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ py: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                    <Box style={{ marginTop: 3, marginRight: 10 }}>
                        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </Box>
                </Box>
                <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                    <RouterLink underline="hover" color="inherit" to="/app/brokers">
                        Brokers
                    </RouterLink>
                    <p>Create</p>
                </Breadcrumbs>
            </Box>
            <form>
                <Card>
                  <CardHeader
                    title="Create new broker"
                  />
                  <Divider />
                  <CardContent>
                      <TextField
                        fullWidth
                        label="Name"
                        margin="normal"
                        name="name"
                        onChange={handleName}
                        type="text"
                        value={name}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="City"
                        margin="normal"
                        name="city"
                        onChange={handleCity}
                        type="text"
                        value={city}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="State"
                        margin="normal"
                        name="state"
                        onChange={handleState}
                        type="text"
                        value={state}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Postal code"
                        margin="normal"
                        name="postalCode"
                        onChange={handlePostalCode}
                        type="text"
                        value={postalCode}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Phone"
                        margin="normal"
                        name="phone"
                        onChange={handlePhone}
                        type="text"
                        value={phone}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        margin="normal"
                        name="email"
                        onChange={handleEmail}
                        type="text"
                        value={email}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="General contact"
                        margin="normal"
                        name="generalContact"
                        onChange={handleGeneralContact}
                        type="text"
                        value={generalContact}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Comments"
                        margin="normal"
                        name="comments"
                        onChange={handleComments}
                        type="text"
                        value={comments}
                        variant="outlined"
                        multiline
                        rows={4}
                      />
                      <BrokerAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
                  </CardContent>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={submit}
                      >
                          Create
                      </Button>
                  </Box>
                </Card>
            </form>
        </>
    );
};

export default BrokerFormCreate;
