import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Avatar,
    TableFooter,
    TablePagination
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';
import getAvatar from '../../utils/getAvatar';

const BrokerMembersListResults = ({
    members,
    count,
    limit,
    page,
    onPageChange,
    onRowsPerPageChange
}) => (
    <Card>
        <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 80 }}>
                                Id
                            </TableCell>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                VurvID
                            </TableCell>
                            <TableCell>
                                Email
                            </TableCell>
                            <TableCell>
                                Phone
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {members.map((member) => (
                            <TableRow key={member.id}>
                                <TableCell sx={{ width: 80 }}>
                                    {member.id}
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                        <Avatar src={getAvatar(member)} sx={{ mr: 2 }}>
                                            {getInitials(member.user_login || `${member.user_last_name} ${member.user_first_name}`)}
                                        </Avatar>
                                        <Typography color="textPrimary" variant="body1">
                                            {member.user_login || `${member.user_last_name} ${member.user_first_name}`}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                        {member.user_vurv_id}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {member.user_email}
                                </TableCell>
                                <TableCell>
                                    {member.user_phone}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={onPageChange}
                              onRowsPerPageChange={onRowsPerPageChange}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </Box>
        </PerfectScrollbar>
    </Card>
);

BrokerMembersListResults.propTypes = {
    members: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired
};

export default BrokerMembersListResults;
