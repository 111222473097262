import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Breadcrumbs,
  Container,
  TextField,
  CircularProgress
} from '@material-ui/core';
import EmailEditor from 'react-email-editor';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { usePut, useGet } from '../request';
import EmailTemplateAlert from '../components/email-template/EmailTemplateAlert';

const EmailTemplateEdit = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [template, setTemplate] = useState(null);
  const [values, setValues] = useState({
    key: '',
    subject: '',
    design: ''
  });
  const [alert, setAlert] = useState({
    status: '',
    message: '',
    design: ''
  });
  const emailEditorRef = useRef(null);
  const navigate = useNavigate();
  const put = usePut();
  const get = useGet();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLoad = () => {
    try {
      emailEditorRef.current?.editor?.loadDesign(JSON.parse(values.design));
    } catch (e) {
      console.log(e);
    }
  };

  const getData = () => {
    setIsLoading(true);
    get(`email-template/${id}`)
      .then((response) => {
        setIsLoading(false);
        // console.log(response)
        if (response.result !== 'success') {
          return;
        }
        setTemplate(response.template);
        try {
          emailEditorRef.current?.editor?.loadDesign(JSON.parse(response.template.design));
        } catch (e) {
          console.log(e);
        }
        setValues({
          ...values,
          design: response.template.design,
          key: response.template.key,
          subject: response.template.subject
        });
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const exportHtml = (callback) => {
    emailEditorRef.current?.editor?.exportHtml(
      (data) => callback(data.html, data.design)
    );
  };

  const save = () => {
    exportHtml((html, design) => {
      setIsLoading(true);
      const requestData = {
        subject: values.subject,
        key: values.key,
        body: html,
        design: JSON.stringify(design)
      };

      put(`email-template/${id}`, requestData)
        .then((response) => {
          setIsLoading(false);
          if (response.result === 'error') {
            setAlert({
              status: 'error',
              message: response.message || 'Unknown error'
            });
            return;
          }
          if (response.result === 'success') {
            setAlert({
              status: response.result,
              message: 'Email template successfully edited.'
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    });
  };

  return (
    <>
      <Helmet>
        <title>Create email templates | Material Kit</title>
      </Helmet>
      {isLoading && (
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f4f6f8',
          zIndex: 100
        }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Box style={{ marginTop: 3, marginRight: 10 }}>
                <Button
                  startIcon={<ChevronLeft />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Box>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
              <RouterLink
                underline="hover"
                color="inherit"
                to="/app/email-templates"
              >
                Email templates
              </RouterLink>
              <p>{id}</p>
              <p>Edit</p>
            </Breadcrumbs>
          </Box>
          <form>
            <Card>
              <CardHeader
                subheader="Update email template"
                title="Email template"
              />
              <Divider />
              <CardContent>
                <TextField
                  fullWidth
                  label="Key"
                  margin="normal"
                  name="key"
                  onChange={handleChange}
                  type="text"
                  value={values.key}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Subject"
                  margin="normal"
                  name="subject"
                  onChange={handleChange}
                  type="text"
                  value={values.subject}
                  variant="outlined"
                />
              </CardContent>
            </Card>
            <Box sx={{ my: 3 }}>
              <EmailTemplateAlert
                status={alert.status}
                message={alert.message}
                onClose={() => setAlert({ status: '', message: '' })}
              />
            </Box>
            <Box sx={{ my: 3, flex: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={save}>
                  Edit
                </Button>
              </Box>
              <Box sx={{ my: 3 }}>
                <Card>
                  <CardContent>
                    <EmailEditor
                      ref={emailEditorRef}
                      minHeight="75vh"
                      onLoad={onLoad}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default EmailTemplateEdit;
