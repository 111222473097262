import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Breadcrumbs,
    TextField,
    Checkbox
} from '@material-ui/core';
import {
  LocalizationProvider,
  DatePicker
} from '@material-ui/lab';
import moment from 'moment';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet, usePost } from '../request';
import { uploadURL } from '../enviroment';
import GroupAlert from '../components/group/GroupAlert';

const GroupExpirationDate = () => {
    const { id } = useParams();
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [date, setDate] = useState(null);
    const [isActived, setIsActived] = useState(false);
    const [group, setGroup] = useState(null);
    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });
    const get = useGet();
    const post = usePost();
    const navigate = useNavigate();

    useEffect(() => {
        get(`admin/groups/${id}`)
        .then((response) => {
          if (response.result === 'success') {
            setGroup(response.group);
            setIsActived(!!response.group.expirtaion_actived);
          }
        })
        .catch((e) => console.log(e));
    }, []);

    const handleSubmit = () => {
        if (isActived && (!date || date === 'Invalid date' || (group.expirtaion_date && !moment(group.expirtaion_date).isBefore(date)))) {
          setAlert({
            status: 'error',
            message: 'Invalid date'
          });
          return;
        }

        setIsShowLoader(true);
        const requestData = {
           expirtaion_actived: isActived ? 1 : 0,
            expirtaion_date: isActived ? date : '3024-02-17',
        };

        post(`admin/groups/set-expiration/${id}`, requestData)
        .then((response) => {
            setIsShowLoader(false);
            if (response.result !== 'success') {
                setAlert({
                  status: 'error',
                  message: response.message
                });
                return;
            }
            setAlert({
                status: response.result,
                message: 'Successfully'
            });
        })
        .catch((e) => {
            setIsShowLoader(false);
            console.log(e)
        });
    };

    if (!group || isShowLoader) {
        return (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 5
              }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Group expiration date | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                {group.name}
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/groups">
                                Groups
                            </RouterLink>
                            <RouterLink underline="hover" color="inherit" to={`/app/group/${group.id}`}>
                                {group.name}
                            </RouterLink>
                            <p>Expiration date</p>
                        </Breadcrumbs>
                    </Box>
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title="Set expiration date" titleTypographyProps={{ variant: 'h5' }} component="h1" />
                        <Divider />
                        <CardContent>
                            <Box sx={{ mx: 1 }}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  keyboard
                                  mask="__.__.____"
                                  inputFormat="MM.DD.YYYY"
                                  value={date}
                                  onChange={(newValue) => setDate(newValue)}
                                  disableOpenOnEnter
                                  animateYearScrolling={false}
                                  autoOk
                                  clearable
                                  renderInput={(props) => <TextField {...props} />}
                                />
                              </LocalizationProvider>
                            </Box>
                            <Box sx={{ display: 'flex', m: 1 }}>
                                <Typography sx={{ mr: 1 }} color="textPrimary" variant="body1">
                                    Expirtaion date:
                                </Typography>
                                <Typography color="textPrimary" variant="body1">
                                    {group.expirtaion_date ? moment(group.expirtaion_date).format('MM.DD.YYYY') : '---' }
                                </Typography>
                            </Box>
                            <Box sx={{ alignItems: 'center', display: 'flex', ml: -1 }}>
                                <Checkbox checked={isActived} onChange={() => setIsActived((prev) => !prev)} />
                                <Typography sx={{ whiteSpace: 'nowrap' }} color="textPrimary" variant="body1">
                                    Enable Group Expiration
                                </Typography>
                            </Box>
                            { (alert.status || alert.message)
                            ? (
                                <GroupAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
                            )
                            : undefined}
                        </CardContent>
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default GroupExpirationDate;
