import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const LogTable = function ({ logs }) {
    const navigate = useNavigate();

    if (logs.length === 0) {
        return (
            <Typography color="textPrimary" variant="body1" component="p" sx={{ p: 2, textAlign: 'center', backgroundColor: '#fff' }}>
                Logs not found
            </Typography>
        );
    }

    return (
        <Box>
            <Table sx={{ backgroundColor: '#fff', boxShadow: '0 0 0 1px rgb(63, 63, 68, 0.1)', borderRadius: '4px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 120 }}>
                            Result
                        </TableCell>
                        <TableCell>
                            Date
                        </TableCell>
                        <TableCell>
                            Message
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs.map((item) => {
                        let color = 'inherit';
                        let fontWeight = 'normal';

                        switch (item.type) {
                        case 'success':
                            color = 'green';
                            fontWeight = 'bold';
                            break;
                        case 'error':
                            color = 'red';
                            fontWeight = 'bold';
                            break;
                        case 'warn':
                            color = 'orange';
                            fontWeight = 'bold';
                            break;
                        default:
                            break;
                        }

                        return (
                        <TableRow key={item.id}>
                            <TableCell sx={{ width: 120, fontWeight, color }}>
                            {item.type}
                            </TableCell>
                            <TableCell sx={{ width: 200 }}>
                            {item.date_create}
                            </TableCell>
                            <TableCell>
                            {item.message}
                            </TableCell>
                            <TableCell>
                            {item.data && (
                                <Button
                                  onClick={() => navigate(`/app/logpage/data?log=${JSON.stringify(item)}`)}
                                  color="primary"
                                  variant="contained"
                                >
                                Data
                                </Button>
                            )}
                            </TableCell>
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box>
    );
};

LogTable.propTypes = {
    logs: PropTypes.array.isRequired
};

export default LogTable;
