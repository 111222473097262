import { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Breadcrumbs,
  Container,
  TextField,
  CircularProgress
} from '@material-ui/core';
import EmailEditor from 'react-email-editor';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  usePost
} from '../request';
import EmailTemplateAlert from '../components/email-template/EmailTemplateAlert';

const EmailTemplateCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    key: '',
    subject: ''
  });
  const [alert, setAlert] = useState({
    status: '',
    message: ''
  });
  const emailEditorRef = useRef(null);
  const navigate = useNavigate();
  const post = usePost();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const exportHtml = (callback) => emailEditorRef.current.editor.exportHtml((data) => callback(data.html, data.design));

  const create = () => {
    exportHtml((html, design) => {
      setIsLoading(true);
      const requestData = {
        subject: values.subject,
        key: values.key,
        body: html,
        design: JSON.stringify(design)
      };

      post('email-template', requestData)
      .then((response) => {
        setIsLoading(false);
        if (response.result === 'error') {
          setAlert({
            status: 'error',
            message: response.message || 'Unknown error'
          });
          return;
        }
        if (response.result === 'success') {
          setAlert({
            status: response.result,
            message: 'Email template successfully created.'
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e)
      });
    })
  }

  return (
    <>
        <Helmet>
            <title>Create email templates | Material Kit</title>
        </Helmet>
        {isLoading && (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f4f6f8',
            zIndex: 100
          }}
          >
              <CircularProgress />
          </Box>
        )}
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
            <Container maxWidth={false}>
                <Box sx={{ py: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                        <Box style={{ marginTop: 3, marginRight: 10 }}>
                            <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                Back
                            </Button>
                        </Box>
                    </Box>
                    <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                        <RouterLink underline="hover" color="inherit" to="/app/email-templates">
                            Email templates
                        </RouterLink>
                        <p>Create</p>
                    </Breadcrumbs>
                </Box>
                <form>
                  <Card>
                    <CardHeader
                      subheader="Create email template"
                      title="Email template"
                    />
                    <Divider />
                    <CardContent>
                      <TextField
                        fullWidth
                        label="Key"
                        margin="normal"
                        name="key"
                        onChange={handleChange}
                        type="text"
                        value={values.key}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Subject"
                        margin="normal"
                        name="subject"
                        onChange={handleChange}
                        type="text"
                        value={values.subject}
                        variant="outlined"
                      />
                    </CardContent>
                  </Card>
                  <Box sx={{ my: 3 }}>
                    <EmailTemplateAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
                  </Box>
                  <Box sx={{ my: 3, flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button color="primary" variant="outlined" onClick={() => navigate(-1)}>
                        Cancel
                      </Button>
                      <Button color="primary" variant="contained" onClick={create}>
                        Create
                      </Button>
                    </Box>
                    <Box sx={{ my: 3 }}>
                      <Card>
                        <CardContent>
                            <EmailEditor ref={emailEditorRef} minHeight="75vh" />
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </form>
            </Container>
        </Box>
    </>
  );
};

export default EmailTemplateCreate;
