import {
   Card,
   CardContent,
   Grid,
   Typography,
   Box,
   Link
  } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: '70%',
    padding: '4px 8px',
    marginRight: '8px',
  },
  text: {
    color: 'black',
  },
}));

const CheckErrors = ({ errorCount, errorLinks, ...props }) => {
  const classes = useStyles();

  return (
    <Card {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography color="textSecondary" gutterBottom variant="h6">
              {errorCount && (
                <span className={classes.badge}>{errorCount}</span>
              )}
                <span className={classes.text}>
                {errorCount ? 'Your intervention is required' : 'Nothing need to do!'}
                </span>
              </Typography>
            </Box>
            {errorCount && errorLinks && errorLinks.map((link) => (
              <Typography key={link} color="textPrimary" variant="body1">
                <Link href={link} target="_blank" rel="noopener">
                  {link}
                </Link>
              </Typography>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CheckErrors.propTypes = {
  errorCount: PropTypes.number,
  errorLinks: PropTypes.arrayOf(PropTypes.string),
};

CheckErrors.defaultProps = {
  errorCount: 0,
  errorLinks: [],
};

export default CheckErrors;
