import { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Breadcrumbs,
  Checkbox
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  usePut,
  useGet
} from '../../request';
import GroupAlert from './GroupAlert';

const GroupFormCreate = () => {
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [generalContact, setGeneralContact] = useState('');
    const [comments, setComments] = useState('');
    const [brokerId, setBrokerId] = useState('');
    const [brokerList, setBrokerList] = useState([]);
    const [is_for_stripe, setIs_for_stripe] = useState(1);
    const [isActived, setIsActived] = useState(false);
    const [alert, setAlert] = useState({
      status: '',
      message: ''
    });
    const navigate = useNavigate();
    const put = usePut();
    const get = useGet();

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleCity = (event) => {
        setCity(event.target.value);
    };

    const handleState = (event) => {
        setState(event.target.value);
    };

    const handlePostalCode = (event) => {
        setPostalCode(event.target.value);
    };

    const handlePhone = (event) => {
        setPhone(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleGeneralContact = (event) => {
        setGeneralContact(event.target.value);
    };

    const handleComments = (event) => {
        setComments(event.target.value);
    };

    const handleBrokerId = (event) => {
        setBrokerId(event.target.value);
    };

    const handleSetStripeId = (event) => {
        setIs_for_stripe(event.target.value);
    };

  const loadBrokers = () => {
        get('admin/brokers')
        .then((response) => {
            if (response.result === 'success') {
                setBrokerList(response.brokers);
            }
        })
        .catch((e) => console.log(e));
    };

    const submit = () => {
      const requestData = {
        name,
        city,
        state,
        postal_code: postalCode,
        phone,
        email,
        general_contact: generalContact,
        comments,
        broker_id: brokerId,
        isActived: isActived ? 1 : 0
      };

      // console.log(requestData);

      put('admin/groups', requestData)
      .then((response) => {
        if (response.result === 'success') {
          setAlert({
            status: response.result,
            message: 'Successfully'
          });
          return
        }
        setAlert({
          status: response.result,
          message: response.message
        });
      })
      .catch((e) => console.log(e));
    };

    useEffect(() => {
      loadBrokers();
    }, []);

    return (
      <>
        <Box sx={{ py: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                <Box style={{ marginTop: 3, marginRight: 10 }}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Box>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                <RouterLink underline="hover" color="inherit" to="/app/groups">
                    Groups
                </RouterLink>
                <p>Create</p>
            </Breadcrumbs>
        </Box>
        <form>
          <Card>
            <CardHeader
              title="Create new group"
            />
            <Divider />
            <CardContent>
                <TextField
                  fullWidth
                  label="Name"
                  margin="normal"
                  name="name"
                  onChange={handleName}
                  type="text"
                  value={name}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="City"
                  margin="normal"
                  name="city"
                  onChange={handleCity}
                  type="text"
                  value={city}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="State"
                  margin="normal"
                  name="state"
                  onChange={handleState}
                  type="text"
                  value={state}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Postal code"
                  margin="normal"
                  name="postalCode"
                  onChange={handlePostalCode}
                  type="text"
                  value={postalCode}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Phone"
                  margin="normal"
                  name="phone"
                  onChange={handlePhone}
                  type="text"
                  value={phone}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  onChange={handleEmail}
                  type="text"
                  value={email}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="General contact"
                  margin="normal"
                  name="generalContact"
                  onChange={handleGeneralContact}
                  type="text"
                  value={generalContact}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Comments"
                  margin="normal"
                  name="comments"
                  onChange={handleComments}
                  type="text"
                  value={comments}
                  variant="outlined"
                  multiline
                  rows={4}
                />
              <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                <InputLabel id="is_for_stripe">Enable Stripe Mode</InputLabel>
                <Select
                  labelId="is_for_stripe"
                  name="is_for_stripe"
                  value={is_for_stripe}
                  label="Enable Stripe Mode"
                  onChange={handleSetStripeId}
                >
                  <MenuItem value={0}>True</MenuItem>
                  <MenuItem value={1}>False</MenuItem>
                </Select>
              </FormControl>
              <Typography style={{
                textAlign: 'center'
              }}
              >
                Works like D2C group. If you enabled this options, all users expired by payments
              </Typography>
                <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                  <InputLabel id="brokerId">Broker</InputLabel>
                  <Select
                    labelId="brokerId"
                    name="brokerId"
                    value={brokerId}
                    label="Broker"
                    onChange={handleBrokerId}
                  >
                    {brokerList.map((broker) => (
                      <MenuItem value={broker.id}>{broker.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ alignItems: 'center', display: 'flex', ml: -1 }}>
                    <Checkbox checked={isActived} onChange={() => setIsActived((prev) => !prev)} />
                    <Typography sx={{ whiteSpace: 'nowrap' }} color="textPrimary" variant="body1">
                        Actived
                    </Typography>
                </Box>
                <GroupAlert status={alert.status} message={alert.message} onClose={() => setAlert({ status: '', message: '' })} />
            </CardContent>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                >
                    Create
                </Button>
            </Box>
          </Card>
        </form>
      </>
    );
};

export default GroupFormCreate;
