/* eslint-disable */
import React, { useState , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Typography,
    Breadcrumbs,
    Button,
    CircularProgress,
    TextField,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Card,
    CardContent,
    Checkbox
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useGet, usePost } from "../request";
import { useAlert } from '../components/alert';

const SubscriptionCreate = function () {
    const [values, setValues] = useState({
        name: '',
        amount: '',
        duration: '',
        stripe_plan_id: '',
        group_id: '',
    });
    const [products, setProducts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [isActivedFamaly, setIsActivedFamaly] = useState(false);
    const alert = useAlert();
    const navigate = useNavigate();
    const post = usePost();
    const get = useGet();

    useEffect(() => {
        (async () => {
            setIsShowLoader(true);
            await loadProducts();
            await loadGroups();
            setIsShowLoader(false)
        })()
    }, []);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const loadProducts = async () => {
        const response = await get('api/strapi/get-products')
        if (response.result === 'success') {
            setProducts(response.products || []);
        }
    };

    const loadGroups = async () => {
        const response = await get('admin/group/get-for-stripe')
        if (response.result === 'success') {
            setGroups(response.groups);
        }
    };

    const submit = async () => {
        if (!values.name) {
            alert({
                status: 'error',
                message: 'Name is required'
            });
            return;
        }

        setIsShowLoader(true);
        const requestData = {
            name: values.name,
            amount: values.amount,
            duration: values.duration,
            stripe_plan_id: values.stripe_plan_id,
            group_id: values.group_id !== '' ? values.group_id : 0,
            family_members_access: isActivedFamaly ? 1 : 0,
        };

        post(`admin/subscriptions`, requestData)
            .then((response) => {
                if (response.result === 'success') {
                    alert({
                        status: 'success',
                        message: 'Successfully'
                    });
                } else {
                    alert({
                        status: 'error',
                        message: response?.message
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                alert({
                    status: 'error',
                    message: e?.response?.message
                });
            })
            .finally(() =>{
                setIsShowLoader(false);
            });
    };

    return (
        <>
            <Helmet>
                <title>Subscription Create | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    { isShowLoader ? (
                        <Box sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 5
                          }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ py: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                                    <Box style={{ marginTop: 3, marginRight: 10 }}>
                                        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                            Back
                                        </Button>
                                    </Box>
                                    <Typography color="textPrimary" variant="h4" component="h1">
                                        Subscription Create
                                    </Typography>
                                </Box>
                                <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                                    <RouterLink underline="hover" color="inherit" to="/app/subscriptions">
                                        Subscriptions
                                    </RouterLink>
                                    <p>Subscription Create</p>
                                </Breadcrumbs>
                            </Box>
                            <Card>
                                <CardContent sx={{ position: 'relative' }}>
                                    <TextField
                                      fullWidth
                                      label="Name"
                                      margin="normal"
                                      name="name"
                                      onChange={handleChange}
                                      type="text"
                                      value={values.name}
                                      variant="outlined"
                                    />
                                    <TextField
                                      fullWidth
                                      label="Payment amount in Number (1.00$ = 100)"
                                      margin="normal"
                                      name="amount"
                                      onChange={handleChange}
                                      type="text"
                                      value={values.amount}
                                      variant="outlined"
                                    />
                                    <TextField
                                      fullWidth
                                      label="Duration"
                                      margin="normal"
                                      name="duration"
                                      onChange={handleChange}
                                      type="text"
                                      value={values.duration}
                                      variant="outlined"
                                    />
                                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                        <InputLabel id="stripePlanIdLabel">Product</InputLabel>
                                        <Select
                                          labelId="stripePlanIdLabel"
                                          name="stripe_plan_id"
                                          value={values.stripe_plan_id}
                                          label="Product"
                                          onChange={handleChange}
                                        >
                                            <MenuItem value="">No select</MenuItem>
                                            {products.map(({ name, plan_id }) => (
                                                <MenuItem value={plan_id}>{name} [{plan_id}]</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="stripePlanIdLabel">Group</InputLabel>
                                        <Select
                                            labelId="group_id"
                                            name="group_id"
                                            value={values.group_id}
                                            label="Group"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={0}>No select</MenuItem>
                                            {groups.map(({name, id}) => (
                                                <MenuItem value={id}>{name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Typography style={{textAlign: 'center'}}>
                                        If you set this options, all users who purchase this subscription is get to choiced group
                                    </Typography>
                                    <Box sx={{ alignItems: 'center', display: 'flex', ml: -1 }}>
                                         <Checkbox checked={isActivedFamaly} onChange={() => setIsActivedFamaly((prev) => !prev)} />
                                         <Typography sx={{ whiteSpace: 'nowrap' }} color="textPrimary" variant="body1">
                                         Allow to add Family Members
                                         </Typography>
                                    </Box>
                                </CardContent>
                                <Divider />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={submit}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </>
                    )}
                </Container>
            </Box>
        </>
    );
};

export default SubscriptionCreate;
