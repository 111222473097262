/* eslint-disable */
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { states } from './data';



const CustomerSelectState = ({ value, handleChange, mt = 2, mb = 1 }) => {
 return (
    <FormControl fullWidth sx={{ mt, mb }}>
      <InputLabel id="stateLabel">State *</InputLabel>
      <Select
        labelId="stateLabel"
        name="state"
        value={value}
        label="State"
        onChange={handleChange}
      >
        {states.map((state) => (
          <MenuItem key={state.abbreviation} value={state.abbreviation}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
 );
};

export { CustomerSelectState };