import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    CircularProgress,
    Button,
    Container,
    Breadcrumbs,
    Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DbSpeedLog from '../components/DbSpeedLog';
import { useGet } from '../request';

const DbSpeedLogPage = function () {
    let [dataLoading, setDataLoading] = useState(false);
    let [dbSpeedLogs, setDbSpeedLogs] = useState([]);
    const navigate = useNavigate();
    const get = useGet();

    function parseLogs(rawData) {
        let logs = rawData.trim().split('\n');
        let logObjects = [];
        let queryStr;
        let queryTime;

        logs.forEach((item, ind) => {
            if (ind !== 0 && ind !== logs.length - 1) {
                queryStr = item.trim().split(' ');
                queryTime = queryStr[queryStr.length - 1];
                queryStr.splice(queryStr.length - 1, 1);

                logObjects.push({
                    id: ind,
                    query: queryStr.join(' '),
                    time: queryTime
                });
            }
        });

        setDbSpeedLogs(logObjects);
    }

    function loadLogsData() {
        setDataLoading(true);
        get('admin/get-db-test')
            .then((response) => {
                setDataLoading(false);
                if (response.result === 'success') {
                    parseLogs(response.message);
                }
            })
            .catch((evt) => {
                setDataLoading(false);
                console.log(evt)
            });
    }

    useEffect(() => {
        loadLogsData();
    }, []);

    return (
        <>
            <Helmet>
                <title>DB speed log | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                DB speed log
                            </Typography>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <p>DB speed log</p>
                        </Breadcrumbs>
                    </Box>
                    { dataLoading ? (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 5
                          }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DbSpeedLog logs={dbSpeedLogs} />
                    )}
                </Container>
            </Box>
        </>
    );
};

export default DbSpeedLogPage;
